import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const PreviousResidentialSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        { name: "address", type: "Address", typeLink: RouteTable.SharedModel.Address, required: true, description: "The applicant’s previous residential address." },
        {
            name: "duration",
            type: "int",
            validation: "Must be between 0, and 1,200",
            required: true,
            description: "The duration, in months, that the applicant lived at this previous address.",
        },
        {
            name: "status",
            type: "DynamicResidentialStatus",
            typeLink: RouteTable.Reference.DynamicTypes.Residential,
            required: true,
            description: "The residential status of the applicant’s previous address. A list of valid values for this field can be retrieved from the Dynamic Types API endpoint.",
        },
        {
            name: "endDate",
            type: "string",
            required: true,
            validation: "Date should be submitted in 'yyyy-MM-dd' format. Must be a date between 1 day, and 100 years old at the time of submission",
            description: "The date the applicant stopped living at this previous address.",
        },
    ];
    return (
        <RcPage title="Previous Residential" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Residential}>ResidentialApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
