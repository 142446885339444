import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const VisaTypeExistsComplexValidator: React.FC = () => {
    return (
        <RcPage title="Visa Type Exists" description="Complex Validator">
            <RcSection title="Purpose">
                <p>
                    Ensures that a valid visa type is provided, if the applicant indicates that their <code>residencyStatus</code> is 'visa'.
                </p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Personal}>PersonalApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
