import "./Dashboard.scss";

import { RcAlert, RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../manifold/RouteTable";

export const Dashboard: React.FC = () => {
    return (
        <>
            <RcPage>
                <div className="dashboard-backdrop">
                    <div className="dashboard-backdrop-fill">
                        <div className="inner">API Documentation</div>
                    </div>
                </div>
                <RcAlert icon="Information" title="This API Requires an API Key" variant="primary">
                    <p>
                        All requests made to the Finance One API must provide a valid API key in the HTTP headers. API keys are supplied to accredited brokerage groups upon completion of the application process. For more information, please contact your local
                        BDM.
                    </p>
                </RcAlert>
                <RcAlert icon="Alert" title="Disclaimer" variant="warning">
                    <p>
                        The content of this document is protected by copyright. Except as permitted by copyright law, you may not reproduce any of the content of this document without the permission of the copyright owner, Fin One Pty Ltd (trading as Finance
                        One).
                    </p>
                    <p>Finance One permits approved introducers to access the Finance One API to use and communicate content in which Finance One owns copyright for the purpose of submitting loan applications to Finance One by their organisation.</p>

                    <p>We may change the content of this document from time to time. Please check before re-using any content from this document.</p>
                </RcAlert>
                <RcSection title="About the API">
                    <p>
                        The Finance One API has been built with the purpose of providing an alternative method of application submission for accredited brokerage groups. Developers can make use of this API to integrate the Finance One submission process with
                        their existing systems, using the provided endpoints to retrieve the necessary data required for a valid submission, and then submit applications programmatically.
                    </p>
                </RcSection>
                <RcSection title="Future Updates">
                    <p>
                        As Finance One is constantly evolving and improving it's application structures and systems, there is a high chance that the models, validators, and endpoints detailed in this document will be subject to changes in the future. Finance
                        One will endeavour to provide all API users with at least 30 days notice before deploying any such changes, to give all users time to update their relevant systems.
                    </p>
                    <p>
                        The API also makes use of several dynamic fields across our application structures for fields that are subject to frequent updates. These fields have corresponding endpoints which can be used to retrieve an up to date list of currently
                        accepted values. It is highly recommended that all users make use of these endpoints in their integrations, to avoid interruptions caused by changing fields. For more details on these dynamic fields, please refer to the Product Types,
                        and Dynamic Types sections of this documentation.
                    </p>
                </RcSection>
                <RcSection title="Application Types">
                    <p>
                        The Finance One API currently accepts 6 types of applications. Each application type is composed of a series of sections, some specifically made for certain types of applicants, and others generic and shared between the application
                        structures.
                    </p>
                    <ul>
                        <li>
                            <Link to={RouteTable.ApplicationType.Individual}>Individual</Link> - Used for consumer applications where 1-2 individual/s are applying for finance.
                        </li>
                        <li>
                            <Link to={RouteTable.ApplicationType.SoleTrader}>Sole Trader</Link> - Used for applications where an individual is applying for finance as a sole-trader entity.
                        </li>
                        <li>
                            <Link to={RouteTable.ApplicationType.Company}>Company</Link> - A generic commercial application where a commercial entity is applying for finance with 1 or more directors applying as guarantor.
                        </li>
                        <li>
                            <Link to={RouteTable.ApplicationType.Partnership}>Partnership</Link> - This application type specifically caters to partnership entities applying for finance, with the partners applying as guarantors.
                        </li>
                        <li>
                            <Link to={RouteTable.ApplicationType.TrustIndividual}>Trust with Individual Trustee</Link> - A trust entity with one or more individuals acting as trustees, that is applying for finance.
                        </li>
                        <li>
                            <Link to={RouteTable.ApplicationType.TrustCompany}>Trust with Company Trustee</Link> - A trust entity with a commercial entity acting as trustee, that is applying for finance.
                        </li>
                    </ul>
                </RcSection>
                <RcSection title="Application Validation">
                    <p>When submitting an application to its relevant submission endpoint, it will be evaluated by two stages of validation. If the application is found to be invalid at either stage, a list of errors to be rectified will be returned.</p>
                    <p>The first stage is simple form validation, making sure that all the models required fields have been provided, and that these fields are using the expected data types.</p>
                    <p>
                        Once simple validation has been run successfully, the application will be passed through a series of complex validators. These validators extend beyond the simple form validation, ensuring that the submitted application adheres to our
                        business rules, and that all information required to make a reasonable assessment has been provided.
                    </p>
                    <p>The details of each complex validator can be found in the Complex Validation section of this documentation.</p>
                </RcSection>
            </RcPage>
        </>
    );
};
