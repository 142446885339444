import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const DependantsExpensesProvidedComplexValidator: React.FC = () => {
    return (
        <RcPage title="Dependant's Expenses Provided" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that all dependant expenses that are not included in the living expense value, are included in the application as a non-zero value.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSection.HouseholdExpenses}>HouseholdExpensesApplicationSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
