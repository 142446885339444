import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const TrusteeCompanyApplicantType: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "creditHistory",
            type: "CreditHistory",
            typeLink: RouteTable.ApplicantSection.CreditHistory,
            required: true,
            description: "A brief description of the applicant's credit file.",
        },
        {
            name: "entity",
            type: "Entity",
            typeLink: RouteTable.ApplicantSection.Entity,
            required: true,
            description: "Details of the trustee entity applying for the proposed loan.",
        },
    ];
    return (
        <RcPage title="Trustee Company Applicant" description="Applicant Type">
            <RcSection title="Remarks">
                <p>The trustee company applicant type is used solely for trust with a company trustee type applications.</p>
            </RcSection>
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustCompany}>TrustCompanyApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
