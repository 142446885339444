import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const CommercialIndividualEmploymentApplicantSection: React.FC = () => {
    const dataGridRows: ClassDataGridRow[] = [
        {
            name: "guarantorEmployment",
            type: "GuarantorEmployment",
            typeLink: RouteTable.SharedModel.GuarantorEmployment,
            required: true,
            description: "Details of the guarantor's employment with the applying entity.",
        },
        {
            name: "standardEmployments",
            type: "StandardEmployment",
            typeLink: RouteTable.SharedModel.StandardEmployment,
            required: true,
            description: "Details of any other employments the applicant has had in the past 3 years.",
        },
    ];

    return (
        <RcPage title="Commercial Individual Employment Section" description="Applicant Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={dataGridRows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.Guarantor}>GuarantorApplicantType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
