import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const MotorbikeSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "motorbikeType",
            type: "EnumMotorbikeType",
            typeLink: RouteTable.Reference.Enums.MotorbikeType,
            required: true,
            description: "The type of motorbike.",
        },
        {
            name: "make",
            type: "string",
            required: true,
            description: "The make of the motorbike.",
            validation: "Must be between 1, and 50 characters in length",
        },
        {
            name: "model",
            type: "string",
            required: true,
            description: "The model of the motorbike.",
            validation: "Must be between 1, and 50 characters in length",
        },
        {
            name: "year",
            type: "int",
            required: true,
            description: "The year of manufacture of the motorbike.",
            validation: "Must be between 1850, and 2200",
        },
        {
            name: "hasOdometer",
            type: "boolean",
            required: true,
            description: "Indicates if the motorbike is fitted with an odometer.",
        },
        {
            name: "odometer",
            type: "int",
            required: "This is required if hasOdometer is set to true.",
            description: "The current reading of the motorbike's odometer, if applicable.",
            validation: "Must be between 0, and 10,000,000",
        },
        {
            name: "isUnderFinance",
            type: "boolean",
            required: true,
            description: "Indicates whether the motorbike is currently under finance.",
        },
        {
            name: "vin",
            type: "string",
            required: false,
            description: "The Vehicle Identification Number of the motorbike.",
            validation: "Must be 17 characters in length",
        },
        {
            name: "nvic",
            type: "string",
            required: false,
            description: "The National Vehicle Identification Code of the motorbike.",
            validation: "Must be 6 characters in length",
        },
    ];
    return (
        <RcPage title="Motorbike" description="Application Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSection.Security}>SecurityApplicationSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
