import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../manifold/RouteTable";

export const AddressSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "street",
            validation: "Must be between 3, and 150 characters in length",
            type: "string",
            required: true,
            description: "The full street details.",
        },
        {
            name: "suburb",
            validation: "Must be between 3, and 75 characters in length",
            type: "string",
            required: true,
            description: "The suburb.",
        },
        {
            name: "state",
            type: "EnumState",
            typeLink: RouteTable.Reference.Enums.AustralianState,
            required: true,
            description: "The state.",
        },
        {
            name: "postcode",
            type: "string",
            validation: "Must be 4 characters in length",
            required: true,
            description: "The postcode.",
        },
    ];
    return (
        <RcPage title="Address" description="Shared Model">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentResidential}>CurrentResidentialApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PreviousResidential}>PreviousResidentialApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PostalResidential}>PostalResidentialApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Entity}>EntityApplicantSection</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.Entity}>EntityApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentStandardEmployment}>CurrentStandardEmploymentApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PreviousStandardEmployment}>PreviousStandardEmploymentApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
