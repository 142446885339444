import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../../utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const ExamplePostalResidentialSharedModels = [
    {
        sameAsCurrentAddress: true,
    },
    {
        sameAsCurrentAddress: false,
        address: {
            street: "1 Example St",
            suburb: "ExampleSuburb",
            state: "qld",
            postcode: "0000",
        },
    },
];

export const PostalResidentialSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "sameAsCurrentAddress",
            type: "boolean",
            required: true,
            description: "Indicates whether the applicant’s postal address is the same as their current address.",
        },
        {
            name: "address",
            type: "Address",
            typeLink: RouteTable.SharedModel.Address,
            required: "This is required if sameAsCurrentAddress is set to false.",
            description: "The applicant's postal address.",
        },
    ];
    return (
        <RcPage title="Postal Residential" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Postal Address Provided If Required Validator" link={RouteTable.Reference.ComplexValidators.PostalAddressProvidedIfRequired}>
                    A postal address should be provided if the applicant indicates that it is different to their provided current address.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExamplePostalResidentialSharedModels[0]} />
                <CodeBlock code={ExamplePostalResidentialSharedModels[1]} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Residential}>ResidentialApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
