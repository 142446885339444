import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../manifold/RouteTable";
import { ValidatorReference } from "../../utils/ValidatorReference/ValidatorReference";

export const ExamplePointOfContactSharedModels = [
    {
        name: "John Smith",
        phone: "0712341234",
    },
    {
        name: "Jane Smith",
        email: "jane.smith@financeone.com.au",
    },
    {
        name: "John Smith",
        phone: "0712341234",
        email: "john.smith@financeone.com.au",
    },
];

export const PointOfContactSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "name",
            type: "string",
            validation: "Must be between 2, and 50 characters in length",
            required: true,
            description: "The name of the contact.",
        },
        {
            name: "phone",
            type: "string",
            validation: "Must be between 6, and 12 characters in length",
            required: "This is required if no value for email is provided.",
            description: "The best phone number to contact.",
        },
        {
            name: "email",
            type: "string",
            validation: "Must be between 5, and 150 characters in length",
            required: "This is required if no value for phone is provided.",
            description: "The email address to contact.",
        },
    ];
    return (
        <RcPage title="Point Of Contact" description="Shared Model">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Point of Contact Provided Validator" link={RouteTable.Reference.ComplexValidators.PointOfContactProvided}>
                    Each point of contact should provide at least one email address or phone number.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExamplePointOfContactSharedModels[0]} />
                <CodeBlock code={ExamplePointOfContactSharedModels[1]} />
                <CodeBlock code={ExamplePointOfContactSharedModels[2]} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentResidential}>CurrentResidentialApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Entity}>EntityApplicantSection</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.Entity}>EntityApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentStandardEmployment}>CurrentStandardEmploymentApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PreviousStandardEmployment}>PreviousStandardEmploymentApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
