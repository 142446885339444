import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../../utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const ExamplePersonalApplicantSections = [
    {
        isExistingClient: false,
        title: "mr",
        firstName: "John",
        middleNames: "James",
        lastName: "Smith",
        dateOfBirth: "1990-01-01",
        sex: "male",
        residencyStatus: "citizen",
        contactMethods: [
            {
                type: "home_phone",
                value: "0712341234",
            },
        ],
    },
    {
        isExistingClient: true,
        title: "ms",
        firstName: "Jane",
        lastName: "Smith",
        dateOfBirth: "1990-01-01",
        sex: "female",
        residencyStatus: "visa",
        visaType: "skilled_independent_visa_175",
        contactMethods: [
            {
                type: "mobile_phone",
                value: "0712341234",
            },
        ],
    },
];

export const PersonalApplicantSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "isExistingClient",
            type: "boolean",
            required: true,
            description: "Indicates if the applicant is an existing Finance One client.",
        },
        {
            name: "title",
            type: "EnumTitle",
            typeLink: RouteTable.Reference.Enums.Title,
            required: true,
            description: "The applicant’s preferred title.",
        },
        {
            name: "firstName",
            type: "string",
            validation: "Must be between 2, and 30 characters in length",
            required: true,
            description: "The applicant’s first name.",
        },
        {
            name: "middleNames",
            type: "string",
            validation: "Must be between 0, and 150 characters in length",
            required: false,
            description: "The applicant’s middle name/s.",
        },
        {
            name: "lastName",
            type: "string",
            validation: "Must be between 2, and 70 characters in length",
            required: true,
            description: "The applicant’s last name.",
        },
        {
            name: "dateOfBirth",
            type: "string",
            required: true,
            validation: "Date should be submitted in 'yyyy-MM-dd'. Must be a date between 17, and 75 years old at the time of submission",
            description: "The applicant’s date of birth.",
        },
        {
            name: "sex",
            type: "EnumSex", //Giggity
            typeLink: RouteTable.Reference.Enums.Sex,
            required: true,
            description: "The applicant’s sex.",
        },
        {
            name: "residencyStatus",
            type: "DynamicResidencyType",
            typeLink: RouteTable.Reference.DynamicTypes.Residencies,
            required: true,
            description: "The applicant’s residency status. A list of valid values for this field can be retrieved from the Dynamic Types API endpoint.",
        },
        {
            name: "visaType",
            type: "DynamicVisaType",
            typeLink: RouteTable.Reference.DynamicTypes.VisaTypes,
            required: "This is required if residencyStatus is set to 'visa'",
            description: "The applicant’s visa type. A list of valid values for this field can be retrieved from the Dynamic Types API endpoint.",
        },
        {
            name: "maritalStatus",
            type: "EnumMaritalStatus",
            typeLink: RouteTable.Reference.Enums.MaritalStatus,
            required: true,
            description: "The applicant’s marital status.",
        },
        {
            name: "contactMethods",
            type: "ContactMethod[]",
            typeLink: RouteTable.SharedModel.ContactMethod,
            required: true,
            description: "A list of the applicant's contact methods. At least one 'home_phone' or 'mobile_phone' item is required for a valid submission.",
        },
    ];
    return (
        <RcPage title="Personal Section" description="Applicant Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Personal Contact Phone Exists" link={RouteTable.Reference.ComplexValidators.PersonalContactPhoneExists}>
                    Applicants should provide at least one "home_phone", or "mobile_phone" type contact method.
                </ValidatorReference>
                <ValidatorReference heading="Visa Type Exists Validator" link={RouteTable.Reference.ComplexValidators.VisaTypeExists}>
                    If the applicant indicates that their residency status is type "visa", the type of visa should be supplied in the provided "visaType" field.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExamplePersonalApplicantSections[0]} />
                <CodeBlock code={ExamplePersonalApplicantSections[1]} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.Individual}>IndividualApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.Guarantor}>GuarantorApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.SoleTrader}>SoleTraderApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.TrusteeIndividual}>TrusteeIndividualApplicantType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
