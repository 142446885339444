import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../manifold/RouteTable";

export const GuarantorEmploymentSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "duration",
            type: "int",
            validation: "Must be between 0, and 1,200",
            required: true,
            description: "The duration the applicant has been employed with the entity they are acting as guarantor for.",
        },
    ];
    return (
        <RcPage title="Guarantor Employment" description="Shared Model">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.SoleTraderEmployment}>SoleTraderEmploymentApplicantSection</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSection.CommercialIndividualEmployment}>CommercialIndividualEmploymentApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
