import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const LoanApplicationSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "purpose",
            type: "DynamicLoanPurpose",
            typeLink: RouteTable.Reference.DynamicTypes.LoanPurposes,
            required: true,
            description: "The primary purpose of the loan. A list of valid values for this field can be retrieved from the Dynamic Types API endpoint.",
        },
        {
            name: "term",
            type: "int",
            required: true,
            description: "The preferred term of the loan in months.",
            validation: "Must be between 1, and 360",
        },
        {
            name: "repaymentCapacity",
            type: "decimal",
            required: true,
            description: "The estimated payment amount the applicant is capable of.",
            validation: "Must be between 0, and 5,000",
        },
        {
            name: "repaymentFrequency",
            type: "EnumFrequency",
            typeLink: RouteTable.Reference.Enums.Frequency,
            required: true,
            description: "The applicant’s preferred repayment frequency.",
        },
        {
            name: "feePaymentPreference",
            type: "EnumPaymentPreference",
            typeLink: RouteTable.Reference.Enums.PaymentPreference,
            required: true,
            description: "The applicant’s preferred payment method for the initial fees.",
        },
        {
            name: "otherFeaturesOrRequirements",
            type: "string",
            required: true,
            description: "Do the applicants have any particular requirements or objectives for the proposed loan?",
            validation: "Must be between 2, and 10,000 characters in length",
        },
    ];
    return (
        <RcPage title="Loan Section" description="Application Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.Individual}>IndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.SoleTrader}>SoleTraderApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Company}>CompanyApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Partnership}>PartnershipApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustIndividual}>TrustIndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustCompany}>TrustCompanyApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
