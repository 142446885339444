import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../../utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const ExampleLicenceApplicantSections = [
    {
        isLicenceProvided: false,
        reasonForNotProviding: "Applicant does not currently have a driver's licence.",
        vehicleLicences: [],
        marineLicences: [],
    },
    {
        isLicenceProvided: true,
        hasSpecialRestrictions: true,
        specialRestrictionsDetails: "Applicant cannot drive without glasses.",
        vehicleLicences: ["..."],
        marineLicences: [],
    },
];

export const LicenceApplicantSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "isLicenceProvided",
            type: "boolean",
            required: true,
            description: "Indicates whether the applicant has provided a valid licence for this application.",
        },
        {
            name: "reasonForNotProviding",
            type: "string",
            validation: "Must be between 5, and 1,000 characters in length",
            required: "This is required if isLicenceProvided is set to false.",
            description: "The applicant’s reason for not providing a valid licence, if applicable.",
        },
        {
            name: "hasSpecialRestrictions",
            type: "boolean",
            required: true,
            description: "Indicates whether the applicant has any special restrictions applied to their licence.",
        },
        {
            name: "specialRestrictionsDetails",
            type: "string",
            validation: "Must be between 20, and 1,000 characters in length",
            required: "This is required if hasSpecialRestrictions is set to true.",
            description: "Details of the special restrictions applied to the applicant’s licence.",
        },
        {
            name: "vehicleLicences",
            type: "VehicleLicence[]",
            typeLink: RouteTable.ApplicantSubModels.VehicleLicences,
            required: true,
            description: "A list of vehicle licences owned by the applicant.",
        },
        {
            name: "marineLicences",
            type: "MarineLicence[]",
            typeLink: RouteTable.ApplicantSubModels.MarineLicences,
            required: true,
            description: "A list of marine licences owned by the applicant.",
        },
    ];
    return (
        <RcPage title="Licence Section" description="Applicant Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Licence Fields Exist Validator" link={RouteTable.Reference.ComplexValidators.LicenceFieldsExist}>
                    Ensures that if a licence is not provided, a reason is given. Also checks if the supplied licence has special restrictions, and that the details of those restrictions are included if they exist.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExampleLicenceApplicantSections[0]} />
                <CodeBlock code={ExampleLicenceApplicantSections[1]} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.Individual}>IndividualApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.SoleTrader}>SoleTraderApplicantType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
