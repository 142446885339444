import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection, RcText } from "@financeone/core-ui";

import { CodeBlock } from "components/utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { WhitelistedFilesResponseBody } from "manifold/HttpResponseBodies";

export const WhitelistedFiles: React.FC = () => {
    const headerRows: ClassDataGridRow[] = [
        {
            name: "x-api-key",
            type: "String",
            required: true,
            description: "The API key provided to you by Finance One",
        },
    ];

    return (
        <RcPage title="Whitelisted Files Request">
            <RcSection>
                <RcText>
                    <p>
                        Request a JSON object containing an array of valid MIME types accepted by the <Link to={RouteTable.HttpRequests.Documents}>Upload Documents</Link> endpoint.
                    </p>
                    <p>
                        <code>https://sandbox.api.financeone.com.au/api/v1/whitelistedFiles</code>
                    </p>
                </RcText>
            </RcSection>
            <RcSection title="Headers">
                <ClassDataGrid propertiesList={headerRows} />
            </RcSection>
            <RcSection title="Response">
                <CodeBlock language="json" code={WhitelistedFilesResponseBody}></CodeBlock>
            </RcSection>
            <RcSection title="References">
                <ul>
                    <li>
                        <Link to={RouteTable.HttpRequests.Documents}>DocumentsUpload</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
