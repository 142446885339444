export const RouteTable = {
    Dashboard: "/",

    HttpRequests: {
        _root: "/http-requests",
        Documents: "/http-requests/documents",
        DynamicTypes: "/http-requests/dynamic-types",
        Permissions: "/http-requests/permissions",
        WhitelistedFiles: "/http-requests/whitelisted-files",
        Applications: {
            _root: "/http-requests/applications",
            Individual: "/http-requests/applications/individual",
            Company: "/http-requests/applications/company",
            SoleTrader: "/http-requests/applications/sole-trader",
            Partnership: "/http-requests/applications/partnership",
            TrustIndividual: "/http-requests/applications/trust-individual",
            TrustCompany: "/http-requests/applications/trust-company",
        },
        ProductTypes: {
            _root: "/http-requests/product-types",
            FetchAllProducts: "/http-requests/product-types/fetch-all-products",
            FetchAvailableProducts: "/http-requests/product-types/fetch-available-products",
        },
    },

    ApplicationType: {
        _root: "/application-type",
        Individual: "/application-type/individual",
        Company: "/application-type/company",
        SoleTrader: "/application-type/sole-trader",
        Partnership: "/application-type/partnership",
        TrustIndividual: "/application-type/trust-individual",
        TrustCompany: "/application-type/trust-company",
    },
    ApplicationSection: {
        _root: "/application-section",
        Loan: "/application-section/loan",
        Financial: "/application-section/financial",
        Security: "/application-section/security",
        HouseholdExpenses: "/application-section/household-expenses",
        AdditionalInformation: "/application-section/additional-information",
    },
    ApplicationSubModels: {
        _root: "/application-submodel",
        MotorVehicle: "/application-submodel/motor-vehicle",
        Motorbike: "/application-submodel/motorbike",
        RecreationalAsset: "/application-submodel/recreational-asset",
        Watercraft: "/application-submodel/watercraft",
        AllPAAP: "/application-submodel/all-paap",
        OtherGoods: "/application-submodel/other-goods",
        Insurances: "/application-submodel/insurances",
    },
    ApplicantType: {
        _root: "/applicant-type",
        Individual: "/applicant-type/individual",
        CommercialEntity: "/applicant-type/commercial-entity",
        SoleTrader: "/applicant-type/sole-trader",
        TrusteeIndividual: "/applicant-type/trustee-individual",
        TrusteeCompany: "/applicant-type/trustee-company",
        Guarantor: "/applicant-type/guarantor",
    },
    ApplicantSection: {
        _root: "/applicant-section",
        Personal: "/applicant-section/personal",
        Licence: "/applicant-section/licence",
        Residential: "/applicant-section/residential",
        SoleTraderEmployment: "/applicant-section/sole-trader-employment",
        CommercialIndividualEmployment: "/applicant-section/commercial-individual-employment",
        CreditHistory: "/applicant-section/credit-history",
        Entity: "/applicant-section/entity",
        Other: "/applicant-section/other",
        Income: "/applicant-section/income",
        Expenses: "/applicant-section/expenses",
        Liabilities: "/applicant-section/liabilities",
    },
    ApplicantSubModels: {
        _root: "/applicant-submodel",
        Entity: "/applicant-submodel/entity",
        CurrentEmployment: "/applicant-submodel/current-employments",
        CurrentStandardEmployment: "/applicant-submodel/current-standard-employment",
        CurrentSelfEmployment: "/applicant-submodel/current-self-employment",
        CurrentUnemployed: "/applicant-submodel/current-unemployed",
        CurrentBenefit: "/applicant-submodel/current-benefit",
        PreviousEmployment: "/applicant-submodel/previous-employments",
        PreviousStandardEmployment: "/applicant-submodel/previous-standard-employment",
        PreviousSelfEmployment: "/applicant-submodel/previous-self-employment",
        PreviousUnemployed: "/applicant-submodel/previous-unemployed",
        PreviousBenefit: "/applicant-submodel/previous-benefit",
        VehicleLicences: "/applicant-submodel/vehicle-licences",
        MarineLicences: "/applicant-submodel/marine-licences",
        CurrentResidential: "/applicant-submodel/current-residential",
        PreviousResidential: "/applicant-submodel/previous-residential",
        PostalResidential: "/applicant-submodel/postal-residential",
        GeneralLiability: "/applicant-submodel/general-liability",
        CreditFacility: "/applicant-submodel/credit-facility",
    },
    SharedModel: {
        _root: "/shared-models",
        Address: "/shared-models/address",
        ContactMethod: "/shared-models/contact-method",
        PointOfContact: "/shared-models/point-of-contact",
        StandardEmployment: "/shared-models/standard-employment",
        GuarantorEmployment: "/shared-models/guarantor-employment",
    },
    Reference: {
        _root: "/reference",
        ProductType: "/reference/product-type",
        Enums: {
            _root: "/reference/enums",
            AustralianState: "/reference/enums/australian-state",
            EntityType: "/reference/enums/entity-type",
            EmploymentBasis: "/reference/enums/employment-basis",
            Frequency: "/reference/enums/frequency",
            Sex: "/reference/enums/sex",
            Title: "/reference/enums/title",
            MarineLicenceClass: "/reference/enums/marine-licence-class",
            VehicleLicenceType: "/reference/enums/vehicle-licence-type",
            MaritalStatus: "/reference/enums/marital-status",
            PaymentPreference: "/reference/enums/payment-preference",
            MotorbikeType: "/reference/enums/motorbike-type",
            MotorVehicleType: "/reference/enums/motor-vehicle-type",
            RecreationalAssetType: "/reference/enums/recreational-asset-type",
            WatercraftType: "/reference/enums/watercraft-type",
        },
        DynamicTypes: {
            _root: "/reference/dynamic-types",
            Benefits: "/reference/dynamic-types/benefits",
            ContactMethods: "/reference/dynamic-types/contact-methods",
            CreditFacilityLiabilities: "/reference/dynamic-types/credit-facility-liabilities",
            Expenses: "/reference/dynamic-types/expenses",
            Incomes: "/reference/dynamic-types/incomes",
            Insurances: "/reference/dynamic-types/insurances",
            LoanPurposes: "/reference/dynamic-types/loan-purposes",
            Residential: "/reference/dynamic-types/residential",
            Residencies: "/reference/dynamic-types/residencies",
            GeneralLiability: "/reference/dynamic-types/general-liabilities",
            VisaTypes: "/reference/dynamic-types/visa-types",
        },
        ComplexValidators: {
            _root: "/reference/complex-validators",
            BenefitIncomeExists: "/reference/complex-validators/benefit-income-exists",
            CircumstanceNotesExist: "/reference/complex-validators/circumstances-notes-exist",
            CurrentUnemploymentCheck: "/reference/complex-validators/current-unemployment-check",
            DependantExpensesProvided: "/reference/complex-validators/dependant-expenses-provided",
            EmploymentHistoryCheck: "/reference/complex-validators/employment-history-check",
            EmploymentIncomeExists: "/reference/complex-validators/employment-income-exists",
            LicenceFieldsExist: "/reference/complex-validators/licence-fields-exist",
            OtherIncomeHasComment: "/reference/complex-validators/other-income-has-comment",
            PersonalContactPhoneExists: "/reference/complex-validators/personal-contact-phone-exists",
            PointOfContactProvided: "/reference/complex-validators/point-of-contact-provided",
            PostalAddressProvidedIfRequired: "/reference/complex-validators/postal-address-provided-if-required",
            ProductTypeConstraints: "/reference/complex-validators/product-type-constraints",
            RentingLeaseIndividualsProvided: "/reference/complex-validators/renting-lease-individuals-provided",
            ResidentialHistoryCheck: "/reference/complex-validators/residential-history-check",
            SecuritySectionConstraints: "/reference/complex-validators/security-section-constraints",
            SharedLivingExpensesExist: "/reference/complex-validators/shared-living-expenses-exist",
            SingleInsuranceTypeCheck: "/reference/complex-validators/single-insurance-type-check",
            VisaTypeExists: "/reference/complex-validators/visa-type-exists",
        },
    },
};
