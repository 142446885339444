import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection, RcText } from "@financeone/core-ui";

import { CodeBlock } from "components/utils/CodeBlock/CodeBlock";
import { GenericApplicationResponseBody } from "manifold/HttpResponseBodies";
import { Link } from "react-router-dom";
import { PartnershipRequestBody } from "manifold/HttpRequestBodies";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const SoleTraderApplication: React.FC = () => {
    const headerRows: ClassDataGridRow[] = [
        {
            name: "x-api-key",
            type: "String",
            required: true,
            description: "The API key provided to you by Finance One",
        },
        {
            name: "Content-Type",
            type: "application/json",
            required: true,
            description: "The accepted MIME type",
        },
    ];
    const paramsRows: ClassDataGridRow[] = [
        {
            name: "brokerId",
            type: "String",
            required: true,
            description: "Broker Id of the broker employee the application will be submitted under.",
        },
        {
            name: "validateOnly",
            type: "Boolean",
            required: false,
            description: "When set to true, application will be passed through validation, without being submitted for assessment. Defaults to false.",
        },
        {
            name: "sendEmail",
            type: "Boolean",
            required: false,
            description: "When set to true, a pdf copy of the application will be emailed to the submitting broker's current email address upon successful submission. Defaults to true.",
        },
    ];

    return (
        <RcPage title="Sole Trader Application Request">
            <RcSection>
                <RcText>
                    <p>Validate and submit a Sole Trader type application to Finance One for assessment. If the submitted application is invalid, an array of errors will be returned.</p>
                    <p>
                        <code>https://sandbox.api.financeone.com.au/api/v1/applications/soleTrader?brokerId=BE-JJAV&validateOnly=false&sendEmail=true</code>
                    </p>
                </RcText>
            </RcSection>
            <RcSection title="Headers">
                <ClassDataGrid propertiesList={headerRows} />
            </RcSection>
            <RcSection title="Parameters">
                <ClassDataGrid propertiesList={paramsRows} />
            </RcSection>
            <RcSection title="Body">
                <CodeBlock language="json" code={PartnershipRequestBody}></CodeBlock>
            </RcSection>
            <RcSection title="Response">
                <CodeBlock language="json" code={GenericApplicationResponseBody}></CodeBlock>
            </RcSection>
            <RcSection title="References">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.SoleTrader}>SoleTraderApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
