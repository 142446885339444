import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const EntityApplicantSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "tradingName",
            type: "string",
            validation: "Must be between 1, and 256 characters in length",
            required: true,
            description: "The trading name of the applying entity.",
        },
        {
            name: "abn",
            type: "string",
            required: true,
            validation: "Must be a valid ABN number",
            description: "The Australian Business Number of the applying entity.",
        },
        {
            name: "acn",
            type: "string",
            required: false,
            validation: "Must be a valid ACN number",
            description: "The Australian Company Number of the applying entity.",
        },
        {
            name: "accountant",
            type: "PointOfContact",
            typeLink: RouteTable.SharedModel.PointOfContact,
            required: false,
            description: "The contact information of the applying entity's accountant.",
        },
        {
            name: "address",
            type: "Address",
            typeLink: RouteTable.SharedModel.Address,
            required: true,
            description: "The current address of the applying entity.",
        },
        {
            name: "predominantBusinessActivity",
            type: "string",
            validation: "Must be between 1, and 10,000 characters in length",
            required: true,
            description: "A short description of the applying entity's predominant business activity.",
        },
    ];

    return (
        <RcPage title="Entity Section" description="Applicant Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.SoleTrader}>SoleTraderApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.CommercialEntity}>CommercialEntityApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.TrusteeIndividual}>TrusteeCompanyApplicantType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
