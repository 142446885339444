import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const ProductTypeConstraintsComplexValidator: React.FC = () => {
    return (
        <RcPage title="Product Type Constraints" description="Complex Validator">
            <RcSection title="Purpose">
                <p>
                    Ensures that the submitted application adheres to the constraints of the product they have chosen. The validator checks that the term, and lend amount fall within the product's limits and will also ensure that the submitting broker is
                    permitted to submit the selected product type.
                </p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.Individual}>IndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.SoleTrader}>SoleTraderApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Company}>CompanyApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Partnership}>PartnershipApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustIndividual}>TrustIndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustCompany}>TrustCompanyApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
