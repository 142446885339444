import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const ResidentialDynamicType: React.FC = () => {
    return (
        <RcPage title="Residential Statuses" description="Dynamic Type">
            <RcSection title="Remarks">
                <p>
                    Residential statuses are a dynamically populated enumerated type. To obtain a list of valid values, please use the Dynamic Types endpoint. All values retrieved from this endpoint should be added into the application JSON in a string format.
                </p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentResidential}>CurrentResidentialApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PreviousResidential}>PreviousResidentialApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
