import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const TitleEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "mr",
            description: "Mr.",
        },
        {
            value: "mrs",
            description: "Mrs.",
        },
        {
            value: "miss",
            description: "Miss.",
        },
        {
            value: "ms",
            description: "Ms.",
        },
        {
            value: "dr",
            description: "Dr.",
        },
        {
            value: "other",
            description: "Other.",
        },
    ];
    return (
        <RcPage title="Title" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for an applicant's preferred title.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Personal}>PersonalApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
