import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../manifold/RouteTable";
import { ValidatorReference } from "../../utils/ValidatorReference/ValidatorReference";

export const ExampleContactMethodSharedModels = [
    {
        type: "email",
        value: "example@financeone.com.au",
    },
    {
        type: "home_phone",
        value: "0712341234",
    },
];

export const ContactMethodSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "type",
            type: "DynamicContactMethod",
            typeLink: RouteTable.Reference.DynamicTypes.ContactMethods,
            required: true,
            description: "The type of the contact method.",
        },
        {
            name: "value",
            type: "string",
            validation: "Must be between 5, and 150 characters in length",
            required: true,
            description: "The value based on the type selected.",
        },
    ];
    return (
        <RcPage title="Contact Method" description="Shared Model">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Personal Contact Phone Exists" link={RouteTable.Reference.ComplexValidators.PersonalContactPhoneExists}>
                    Applicants should provide at least one "home_phone", or "mobile_phone" type contact method.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExampleContactMethodSharedModels[0]} />
                <CodeBlock code={ExampleContactMethodSharedModels[1]} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Personal}>PersonalApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
