import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const GeneralLiabilitySharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "type",
            type: "DynamicGeneralLiabilityType",
            typeLink: RouteTable.Reference.DynamicTypes.GeneralLiability,
            required: true,
            description: "The general liability type.",
        },
        {
            name: "balance",
            type: "decimal",
            validation: "Must be between 0, and 10,000,000",
            required: true,
            description: "The current balance of the general liability.",
        },
        {
            name: "owedTo",
            type: "string",
            validation: "Must be between 2, and 50 characters in length",
            required: true,
            description: "The name of the entity the general is owed to.",
        },
        {
            name: "monthlyPayment",
            type: "decimal",
            validation: "Must be between 0, and 10,000,000",
            required: true,
            description: "The monthly payment value paid towards the general liability.",
        },
    ];
    return (
        <RcPage title="General Liability" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Liabilities}>LiabilitiesApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
