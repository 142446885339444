import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const MaritalStatusEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "married",
            description: "The person is married.",
        },
        {
            value: "married_with_dependent_spouse",
            description: "The person is married with a dependent spouse.",
        },
        {
            value: "de_facto",
            description: "The person is in a de-facto relationship.",
        },
        {
            value: "de_facto_with_dependent_spouse",
            description: "The person is in a de-facto relationship with a dependent partner.",
        },
        {
            value: "single",
            description: "The person is single.",
        },
        {
            value: "divorced",
            description: "The person is divorced.",
        },
        {
            value: "widowed",
            description: "The person is widowed.",
        },
    ];
    return (
        <RcPage title="Marital Status" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for an applicant's marital status.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Personal}>PersonalApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
