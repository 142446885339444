import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../../utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const ExampleCurrentResidentialSharedModels = [
    {
        address: {
            street: "1 Example St",
            suburb: "ExampleSuburb",
            state: "qld",
            postcode: "0000",
        },
        duration: 12,
        status: "renting",
        contact: {
            name: "Example Landlord",
            phone: "0712341234",
        },
        numberOfIndividualsOnLease: 2,
    },
    {
        address: {
            street: "1 Example St",
            suburb: "ExampleSuburb",
            state: "qld",
            postcode: "0000",
        },
        duration: 12,
        status: "owned_outright",
    },
];

export const CurrentResidentialSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "address",
            type: "Address",
            typeLink: RouteTable.SharedModel.Address,
            required: true,
            description: "The applicant’s current residential address.",
        },
        {
            name: "duration",
            type: "int",
            validation: "Must be between 0, and 1,200",
            required: true,
            description: "The duration, in months, that the applicant has lived at their current address.",
        },
        {
            name: "status",
            type: "DynamicResidentialStatus",
            typeLink: RouteTable.Reference.DynamicTypes.Residential,
            required: true,
            description: "The residential status of the applicant’s current residence. A list of valid values for this field can be retrieved from the Dynamic Types API endpoint.",
        },
        {
            name: "contact",
            type: "PointOfContact",
            typeLink: RouteTable.SharedModel.PointOfContact,
            required: "This field is required if the applicant does not own their home outright.",
            description: "The contact details for the applicant’s current landlord/mortgagee, if applicable.",
        },
        {
            name: "numberOfIndividualsOnLease",
            type: "int",
            validation: "Must be between 0, and 100",
            required: "This field is required if the applicant is currently renting.",
            description: "The number of individuals listed on the applicant’s current lease.",
        },
    ];
    return (
        <RcPage title="Current Residential" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Renting Lease Individuals Provided Validator" link={RouteTable.Reference.ComplexValidators.RentingLeaseIndividualsProvided}>
                    The number of individuals on the lease should be provided if the applicant is renting their current residence.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExampleCurrentResidentialSharedModels[0]} />
                <CodeBlock code={ExampleCurrentResidentialSharedModels[1]} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Residential}>ResidentialApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
