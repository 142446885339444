import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const SoleTraderEmploymentApplicantSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "guarantorEmployment",
            type: "GuarantorEmployment",
            typeLink: RouteTable.SharedModel.GuarantorEmployment,
            required: true,
            description: "Details of the sole trader's employment with the applying entity.",
        },
        {
            name: "receivesPAYGIncome",
            type: "boolean",
            required: true,
            description: "Does the applicant receive PAYG income from sources outside of their self-employment?",
        },
        {
            name: "standardEmployments",
            type: "StandardEmployment",
            typeLink: RouteTable.SharedModel.StandardEmployment,
            required: true,
            description: "Details of any other employments the applicant has had in the past 3 years.",
        },
    ];
    return (
        <RcPage title="Sole Trader Employment Section" description="Applicant Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.SoleTrader}>SoleTraderApplicantType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
