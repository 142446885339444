import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const OtherIncomeHasCommentComplexValidator: React.FC = () => {
    return (
        <RcPage title="Other Income Has Comment" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that if any incomes have been provided of the 'other_income' type, they have a non-empty comment to describe the income source.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Income}>IncomeApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
