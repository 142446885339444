import { RcAlert, RcApplicationPage, RcApplicationPageProps, RcBadge, RcIconType, RcPage } from "@financeone/core-ui";
import { Route, Routes, useLocation } from "react-router-dom";

import { AdditionalInformationApplicationSection } from "components/Pages/Applications/ApplicationSections/AdditionalInformationApplicationSection";
import { AddressSharedModel } from "components/Pages/SharedModels/AddressSharedModel";
import { AllPAAPSharedModel } from "components/Pages/Applications/ApplicationSubModels/AllPAAPSharedModel";
import { AllProductsProductType } from "components/Pages/HttpRequests/ProductTypes/AllProductsProductType";
import { ApplicationCommandBar } from "./AppCommandBar";
import { AustralianStateEnum } from "components/Pages/References/Enums/AustralianStateEnum";
import { BasisEnum } from "components/Pages/References/Enums/BasisEnum";
import { BenefitIncomeExistsComplexValidator } from "components/Pages/Validation/ComplexValidators/BenefitIncomeExistsComplexValidator";
import { BenefitsDynamicType } from "components/Pages/References/DynamicTypes/BenefitsDynamicType";
import { CircumstanceNotesExistComplexValidator } from "components/Pages/Validation/ComplexValidators/CircumstanceNotesExistComplexValidator";
import { CommercialEntityApplicantType } from "components/Pages/Applicants/ApplicantTypes/CommercialEntityApplicantType";
import { CommercialIndividualEmploymentApplicantSection } from "components/Pages/Applicants/ApplicantSections/CommercialIndividualEmploymentApplicantSection";
import { CompanyApplication } from "components/Pages/HttpRequests/Applications/CompanyApplication";
import { CompanyApplicationType } from "components/Pages/Applications/ApplicationTypes/CompanyApplicationType";
import { CompanyTrustApplication } from "components/Pages/HttpRequests/Applications/CompanyTrustApplication";
import { ContactMethodSharedModel } from "components/Pages/SharedModels/ContactMethodSharedModel";
import { ContactMethodsDynamicType } from "components/Pages/References/DynamicTypes/ContactMethodsDynamicType";
import { CreditFacilityLiabilitiesDynamicType } from "components/Pages/References/DynamicTypes/CreditFacilityLiabilitiesDynamicType";
import { CreditFacilitySharedModel } from "components/Pages/Applicants/ApplicantSubModels/CreditFacilitySharedModel";
import { CreditHistoryApplicantSection } from "components/Pages/Applicants/ApplicantSections/CreditHistoryApplicantSection";
import { CurrentBenefitSharedModel } from "components/Pages/Applicants/ApplicantSubModels/CurrentBenefitSharedModel";
import { CurrentEmploymentsSharedModel } from "components/Pages/Applicants/ApplicantSubModels/CurrentEmploymentsSharedModel";
import { CurrentResidentialSharedModel } from "components/Pages/Applicants/ApplicantSubModels/CurrentResidentialSharedModel";
import { CurrentSelfEmploymentsSharedModel } from "components/Pages/Applicants/ApplicantSubModels/CurrentSelfEmploymentsSharedModel";
import { CurrentStandardEmploymentsSharedModel } from "components/Pages/Applicants/ApplicantSubModels/CurrentStandardEmploymentsSharedModel";
import { CurrentUnemployedSharedModel } from "components/Pages/Applicants/ApplicantSubModels/CurrentUnemployedSharedModel";
import { CurrentUnemploymentCheckComplexValidator } from "components/Pages/Validation/ComplexValidators/CurrentUnemploymentCheckComplexValidator";
import { Dashboard } from "components/Pages/Dashboard/Dashboard";
import { DependantsExpensesProvidedComplexValidator } from "components/Pages/Validation/ComplexValidators/DependantsExpensesProvidedComplexValidator";
import { DocumentUpload } from "components/Pages/HttpRequests/Documents/DocumentUpload";
import { DynamicTypes } from "components/Pages/HttpRequests/DynamicTypes/DynamicTypes";
import { EmploymentHistoryCheckComplexValidator } from "components/Pages/Validation/ComplexValidators/EmploymentHistoryCheckComplexValidator";
import { EmploymentIncomeExistsComplexValidator } from "components/Pages/Validation/ComplexValidators/EmploymentIncomeExistsComplexValidator";
import { EntityApplicantSection } from "components/Pages/Applicants/ApplicantSections/EntityApplicantSection";
import { EntitySharedModel } from "components/Pages/Applicants/ApplicantSubModels/EntitySharedModel";
import { EntityTypeEnum } from "components/Pages/References/Enums/EntityTypeEnum";
import { ExpensesApplicantSection } from "components/Pages/Applicants/ApplicantSections/ExpensesApplicantSection";
import { ExpensesDynamicType } from "components/Pages/References/DynamicTypes/ExpensesDynamicType";
import { FinancialApplicationSection } from "components/Pages/Applications/ApplicationSections/FinancialApplicationSection";
import { FrequencyEnum } from "components/Pages/References/Enums/FrequencyEnum";
import { GeneralLiabilitiesDynamicType } from "components/Pages/References/DynamicTypes/GeneralLiabilitiesDynamicType";
import { GeneralLiabilitySharedModel } from "components/Pages/Applicants/ApplicantSubModels/GeneralLiabilitySharedModel";
import { GuarantorApplicantType } from "components/Pages/Applicants/ApplicantTypes/GuarantorApplicantType";
import { GuarantorEmploymentSharedModel } from "components/Pages/SharedModels/GuarantorEmploymentSharedModel";
import { HouseholdExpensesApplicationSection } from "components/Pages/Applications/ApplicationSections/HouseholdExpensesApplicationSection";
import { IncomeApplicantSection } from "components/Pages/Applicants/ApplicantSections/IncomeApplicantSection";
import { IncomesDynamicType } from "components/Pages/References/DynamicTypes/IncomesDynamicType";
import { IndividualApplicantType } from "components/Pages/Applicants/ApplicantTypes/IndividualApplicantType";
import { IndividualApplication } from "components/Pages/HttpRequests/Applications/IndividualApplication";
import { IndividualApplicationType } from "components/Pages/Applications/ApplicationTypes/IndividualApplicationType";
import { IndividualTrustApplication } from "components/Pages/HttpRequests/Applications/IndividualTrustApplication";
import { InsuranceSharedModel } from "components/Pages/Applications/ApplicationSubModels/InsuranceSharedModel";
import { InsurancesDynamicType } from "components/Pages/References/DynamicTypes/InsurancesDynamicType";
import { LiabilitiesApplicantSection } from "components/Pages/Applicants/ApplicantSections/LiabilitiesApplicantSection";
import { LicenceApplicantSection } from "components/Pages/Applicants/ApplicantSections/LicenceApplicantSection";
import { LicenceFieldsExistComplexValidator } from "components/Pages/Validation/ComplexValidators/LicenceFieldsExistComplexValidator";
import { LoanApplicationSection } from "components/Pages/Applications/ApplicationSections/LoanApplicationSection";
import { LoanPurposesDynamicType } from "components/Pages/References/DynamicTypes/LoanPurposesDynamicType";
import { MarineLicenceClassEnum } from "components/Pages/References/Enums/MarineLicenceClassEnum";
import { MarineLicenceSharedModel } from "components/Pages/Applicants/ApplicantSubModels/MarineLicenceSharedModel";
import { MaritalStatusEnum } from "components/Pages/References/Enums/MaritalStatusEnum";
import { MotorVehicleSharedModel } from "components/Pages/Applications/ApplicationSubModels/MotorVehicleSharedModel";
import { MotorVehicleTypeEnum } from "components/Pages/References/Enums/MotorVehicleTypeEnum";
import { MotorbikeSharedModel } from "components/Pages/Applications/ApplicationSubModels/MotorbikeSharedModel";
import { MotorbikeTypeEnum } from "components/Pages/References/Enums/MotorbikeTypeEnum";
import { OtherApplicantSection } from "components/Pages/Applicants/ApplicantSections/OtherApplicantSection";
import { OtherGoodsSharedModel } from "components/Pages/Applications/ApplicationSubModels/OtherGoodsSharedModel";
import { OtherIncomeHasCommentComplexValidator } from "components/Pages/Validation/ComplexValidators/OtherIncomeHasCommentComplexValidator";
import { PartnershipApplication } from "components/Pages/HttpRequests/Applications/PartnershipApplication";
import { PartnershipApplicationType } from "components/Pages/Applications/ApplicationTypes/PartnershipApplicationType";
import { PaymentPreferenceEnum } from "components/Pages/References/Enums/PaymentPreferenceEnum";
import { Permissions } from "components/Pages/HttpRequests/Permissions/Permissions";
import { PermittedBrokersProductType } from "components/Pages/HttpRequests/ProductTypes/PermittedBrokersProductType";
import { PersonalApplicantSection } from "components/Pages/Applicants/ApplicantSections/PersonalApplicantSection";
import { PersonalContactPhoneExistsComplexValidator } from "components/Pages/Validation/ComplexValidators/PersonalContactPhoneExistsComplexValidator";
import { PointOfContactProvidedComplexValidator } from "components/Pages/Validation/ComplexValidators/PointOfContactProvidedComplexValidator";
import { PointOfContactSharedModel } from "components/Pages/SharedModels/PointOfContactSharedModel";
import { PostalAddressProvidedIfRequiredComplexValidator } from "components/Pages/Validation/ComplexValidators/PostalAddressProvidedIfRequiredComplexValidator";
import { PostalResidentialSharedModel } from "components/Pages/Applicants/ApplicantSubModels/PostalResidentialSharedModel";
import { PreviousBenefitSharedModel } from "components/Pages/Applicants/ApplicantSubModels/PreviousBenefitSharedModel";
import { PreviousEmploymentsSharedModel } from "components/Pages/Applicants/ApplicantSubModels/PreviousEmploymentsSharedModel";
import { PreviousResidentialSharedModel } from "components/Pages/Applicants/ApplicantSubModels/PreviousResidentialSharedModel";
import { PreviousSelfEmploymentsSharedModel } from "components/Pages/Applicants/ApplicantSubModels/PreviousSelfEmploymentsSharedModel";
import { PreviousStandardEmploymentsSharedModel } from "components/Pages/Applicants/ApplicantSubModels/PreviousStandardEmploymentsSharedModel";
import { PreviousUnemployedSharedModel } from "components/Pages/Applicants/ApplicantSubModels/PreviousUnemployedSharedModel";
import { ProductType } from "components/Pages/References/ProductType";
import { ProductTypeConstraintsComplexValidator } from "components/Pages/Validation/ComplexValidators/ProductTypeConstraintsComplexValidator";
import { RcApplicationPageMenuSubItem } from "@financeone/core-ui/build/components/application-page/application-page-menu";
import React from "react";
import { RecreationalAssetSharedModel } from "components/Pages/Applications/ApplicationSubModels/RecreationalAssetSharedModel";
import { RecreationalAssetTypeEnum } from "components/Pages/References/Enums/RecreationalAssetTypeEnum";
import { RentingLeaseIndividualsProvidedComplexValidator } from "components/Pages/Validation/ComplexValidators/RentingLeaseIndividualsProvidedComplexValidator";
import { ResidenciesDynamicType } from "components/Pages/References/DynamicTypes/ResidenciesDynamicType";
import { ResidentialApplicantSection } from "components/Pages/Applicants/ApplicantSections/ResidentialApplicantSection";
import { ResidentialDynamicType } from "components/Pages/References/DynamicTypes/ResidentialDynamicType";
import { ResidentialHistoryCheckComplexValidator } from "components/Pages/Validation/ComplexValidators/ResidentialHistoryCheckComplexValidator";
import { RouteTable } from "manifold/RouteTable";
import { SecurityApplicationSection } from "components/Pages/Applications/ApplicationSections/SecurityApplicationSection";
import { SecuritySectionConstraintsComplexValidator } from "components/Pages/Validation/ComplexValidators/SecuritySectionConstraintsComplexValidator";
import { SexEnum } from "components/Pages/References/Enums/SexEnum";
import { SharedLivingExpensesExistComplexValidator } from "components/Pages/Validation/ComplexValidators/SharedLivingExpensesExistComplexValidator";
import { SingleInsuranceTypeCheckComplexValidator } from "components/Pages/Validation/ComplexValidators/SingleInsuranceTypeCheckComplexValidator";
import { SoleTraderApplicantType } from "components/Pages/Applicants/ApplicantTypes/SoleTraderApplicantType";
import { SoleTraderApplication } from "components/Pages/HttpRequests/Applications/SoleTraderApplication";
import { SoleTraderApplicationType } from "components/Pages/Applications/ApplicationTypes/SoleTraderApplicationType";
import { SoleTraderEmploymentApplicantSection } from "components/Pages/Applicants/ApplicantSections/SoleTraderEmploymentApplicantSection";
import { StandardEmploymentSharedModel } from "components/Pages/SharedModels/StandardEmploymentSharedModel";
import { TitleEnum } from "components/Pages/References/Enums/TitleEnum";
import { TrustCompanyApplicationType } from "components/Pages/Applications/ApplicationTypes/TrustCompanyApplicationType";
import { TrustIndividualApplicationType } from "components/Pages/Applications/ApplicationTypes/TrustIndividualApplicationType";
import { TrusteeCompanyApplicantType } from "components/Pages/Applicants/ApplicantTypes/TrusteeCompanyApplicantType";
import { TrusteeIndividualApplicantType } from "components/Pages/Applicants/ApplicantTypes/TrusteeIndividualApplicantType";
import { VehicleLicenceSharedModel } from "components/Pages/Applicants/ApplicantSubModels/VehicleLicenceSharedModel";
import { VehicleLicenceTypeEnum } from "components/Pages/References/Enums/VehicleLicenceTypeEnum";
import { VisaTypeExistsComplexValidator } from "components/Pages/Validation/ComplexValidators/VisaTypeExistsComplexValidator";
import { VisaTypesDynamicType } from "components/Pages/References/DynamicTypes/VisaTypesDynamicType";
import { WatercraftSharedModel } from "components/Pages/Applications/ApplicationSubModels/WatercraftSharedModel";
import { WatercraftTypeEnum } from "components/Pages/References/Enums/WatercraftTypeEnum";
import { WhitelistedFiles } from "components/Pages/HttpRequests/WhitelistedFiles/WhitelistedFiles";

export const ApplicationRoutes: React.FunctionComponent = () => {
    const location = useLocation();

    function _renderRouteTable() {
        return (
            <Routes>
                <Route index element={<Dashboard />} />

                <Route path={RouteTable.HttpRequests.Documents} element={<DocumentUpload />} />
                <Route path={RouteTable.HttpRequests.DynamicTypes} element={<DynamicTypes />} />
                <Route path={RouteTable.HttpRequests.Permissions} element={<Permissions />} />
                <Route path={RouteTable.HttpRequests.WhitelistedFiles} element={<WhitelistedFiles />} />

                <Route path={RouteTable.HttpRequests.Applications.Individual} element={<IndividualApplication />} />
                <Route path={RouteTable.HttpRequests.Applications.Company} element={<CompanyApplication />} />
                <Route path={RouteTable.HttpRequests.Applications.SoleTrader} element={<SoleTraderApplication />} />
                <Route path={RouteTable.HttpRequests.Applications.Partnership} element={<PartnershipApplication />} />
                <Route path={RouteTable.HttpRequests.Applications.TrustIndividual} element={<IndividualTrustApplication />} />
                <Route path={RouteTable.HttpRequests.Applications.TrustCompany} element={<CompanyTrustApplication />} />

                <Route path={RouteTable.HttpRequests.ProductTypes.FetchAllProducts} element={<AllProductsProductType />} />
                <Route path={RouteTable.HttpRequests.ProductTypes.FetchAvailableProducts} element={<PermittedBrokersProductType />} />

                <Route path={RouteTable.ApplicationType.Individual} element={<IndividualApplicationType />} />
                <Route path={RouteTable.ApplicationType.Company} element={<CompanyApplicationType />} />
                <Route path={RouteTable.ApplicationType.SoleTrader} element={<SoleTraderApplicationType />} />
                <Route path={RouteTable.ApplicationType.Partnership} element={<PartnershipApplicationType />} />
                <Route path={RouteTable.ApplicationType.TrustIndividual} element={<TrustIndividualApplicationType />} />
                <Route path={RouteTable.ApplicationType.TrustCompany} element={<TrustCompanyApplicationType />} />

                <Route path={RouteTable.ApplicationSection.Loan} element={<LoanApplicationSection />} />
                <Route path={RouteTable.ApplicationSection.Financial} element={<FinancialApplicationSection />} />
                <Route path={RouteTable.ApplicationSection.Security} element={<SecurityApplicationSection />} />
                <Route path={RouteTable.ApplicationSection.HouseholdExpenses} element={<HouseholdExpensesApplicationSection />} />
                <Route path={RouteTable.ApplicationSection.AdditionalInformation} element={<AdditionalInformationApplicationSection />} />

                <Route path={RouteTable.ApplicationSubModels.MotorVehicle} element={<MotorVehicleSharedModel />} />
                <Route path={RouteTable.ApplicationSubModels.Motorbike} element={<MotorbikeSharedModel />} />
                <Route path={RouteTable.ApplicationSubModels.RecreationalAsset} element={<RecreationalAssetSharedModel />} />
                <Route path={RouteTable.ApplicationSubModels.Watercraft} element={<WatercraftSharedModel />} />
                <Route path={RouteTable.ApplicationSubModels.AllPAAP} element={<AllPAAPSharedModel />} />
                <Route path={RouteTable.ApplicationSubModels.OtherGoods} element={<OtherGoodsSharedModel />} />
                <Route path={RouteTable.ApplicationSubModels.Insurances} element={<InsuranceSharedModel />} />

                <Route path={RouteTable.ApplicantType.Individual} element={<IndividualApplicantType />} />
                <Route path={RouteTable.ApplicantType.CommercialEntity} element={<CommercialEntityApplicantType />} />
                <Route path={RouteTable.ApplicantType.SoleTrader} element={<SoleTraderApplicantType />} />
                <Route path={RouteTable.ApplicantType.TrusteeIndividual} element={<TrusteeIndividualApplicantType />} />
                <Route path={RouteTable.ApplicantType.TrusteeCompany} element={<TrusteeCompanyApplicantType />} />
                <Route path={RouteTable.ApplicantType.Guarantor} element={<GuarantorApplicantType />} />

                <Route path={RouteTable.ApplicantSection.Personal} element={<PersonalApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.Licence} element={<LicenceApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.Residential} element={<ResidentialApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.SoleTraderEmployment} element={<SoleTraderEmploymentApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.CommercialIndividualEmployment} element={<CommercialIndividualEmploymentApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.CreditHistory} element={<CreditHistoryApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.Entity} element={<EntityApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.Other} element={<OtherApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.Income} element={<IncomeApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.Expenses} element={<ExpensesApplicantSection />} />
                <Route path={RouteTable.ApplicantSection.Liabilities} element={<LiabilitiesApplicantSection />} />

                <Route path={RouteTable.ApplicantSubModels.Entity} element={<EntitySharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.CurrentEmployment} element={<CurrentEmploymentsSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.CurrentStandardEmployment} element={<CurrentStandardEmploymentsSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.CurrentSelfEmployment} element={<CurrentSelfEmploymentsSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.CurrentUnemployed} element={<CurrentUnemployedSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.CurrentBenefit} element={<CurrentBenefitSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.PreviousEmployment} element={<PreviousEmploymentsSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.PreviousStandardEmployment} element={<PreviousStandardEmploymentsSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.PreviousSelfEmployment} element={<PreviousSelfEmploymentsSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.PreviousUnemployed} element={<PreviousUnemployedSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.PreviousBenefit} element={<PreviousBenefitSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.VehicleLicences} element={<VehicleLicenceSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.MarineLicences} element={<MarineLicenceSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.CurrentResidential} element={<CurrentResidentialSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.PreviousResidential} element={<PreviousResidentialSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.PostalResidential} element={<PostalResidentialSharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.GeneralLiability} element={<GeneralLiabilitySharedModel />} />
                <Route path={RouteTable.ApplicantSubModels.CreditFacility} element={<CreditFacilitySharedModel />} />

                <Route path={RouteTable.SharedModel.Address} element={<AddressSharedModel />} />
                <Route path={RouteTable.SharedModel.ContactMethod} element={<ContactMethodSharedModel />} />
                <Route path={RouteTable.SharedModel.PointOfContact} element={<PointOfContactSharedModel />} />
                <Route path={RouteTable.SharedModel.StandardEmployment} element={<StandardEmploymentSharedModel />} />
                <Route path={RouteTable.SharedModel.GuarantorEmployment} element={<GuarantorEmploymentSharedModel />} />

                <Route path={RouteTable.Reference.ProductType} element={<ProductType />} />

                <Route path={RouteTable.Reference.Enums.EntityType} element={<EntityTypeEnum />} />
                <Route path={RouteTable.Reference.Enums.AustralianState} element={<AustralianStateEnum />} />
                <Route path={RouteTable.Reference.Enums.EmploymentBasis} element={<BasisEnum />} />
                <Route path={RouteTable.Reference.Enums.Frequency} element={<FrequencyEnum />} />
                <Route path={RouteTable.Reference.Enums.Sex} element={<SexEnum />} />
                <Route path={RouteTable.Reference.Enums.Title} element={<TitleEnum />} />
                <Route path={RouteTable.Reference.Enums.MarineLicenceClass} element={<MarineLicenceClassEnum />} />
                <Route path={RouteTable.Reference.Enums.VehicleLicenceType} element={<VehicleLicenceTypeEnum />} />
                <Route path={RouteTable.Reference.Enums.MaritalStatus} element={<MaritalStatusEnum />} />
                <Route path={RouteTable.Reference.Enums.PaymentPreference} element={<PaymentPreferenceEnum />} />
                <Route path={RouteTable.Reference.Enums.MotorbikeType} element={<MotorbikeTypeEnum />} />
                <Route path={RouteTable.Reference.Enums.MotorVehicleType} element={<MotorVehicleTypeEnum />} />
                <Route path={RouteTable.Reference.Enums.RecreationalAssetType} element={<RecreationalAssetTypeEnum />} />
                <Route path={RouteTable.Reference.Enums.WatercraftType} element={<WatercraftTypeEnum />} />

                <Route path={RouteTable.Reference.DynamicTypes.Benefits} element={<BenefitsDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.ContactMethods} element={<ContactMethodsDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.CreditFacilityLiabilities} element={<CreditFacilityLiabilitiesDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.Expenses} element={<ExpensesDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.GeneralLiability} element={<GeneralLiabilitiesDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.Incomes} element={<IncomesDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.Insurances} element={<InsurancesDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.LoanPurposes} element={<LoanPurposesDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.Residential} element={<ResidentialDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.Residencies} element={<ResidenciesDynamicType />} />
                <Route path={RouteTable.Reference.DynamicTypes.VisaTypes} element={<VisaTypesDynamicType />} />

                <Route path={RouteTable.Reference.ComplexValidators.BenefitIncomeExists} element={<BenefitIncomeExistsComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.CircumstanceNotesExist} element={<CircumstanceNotesExistComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.CurrentUnemploymentCheck} element={<CurrentUnemploymentCheckComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.DependantExpensesProvided} element={<DependantsExpensesProvidedComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.EmploymentHistoryCheck} element={<EmploymentHistoryCheckComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.EmploymentIncomeExists} element={<EmploymentIncomeExistsComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.LicenceFieldsExist} element={<LicenceFieldsExistComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.OtherIncomeHasComment} element={<OtherIncomeHasCommentComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.PersonalContactPhoneExists} element={<PersonalContactPhoneExistsComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.PointOfContactProvided} element={<PointOfContactProvidedComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.PostalAddressProvidedIfRequired} element={<PostalAddressProvidedIfRequiredComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.ProductTypeConstraints} element={<ProductTypeConstraintsComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.RentingLeaseIndividualsProvided} element={<RentingLeaseIndividualsProvidedComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.ResidentialHistoryCheck} element={<ResidentialHistoryCheckComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.SecuritySectionConstraints} element={<SecuritySectionConstraintsComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.SharedLivingExpensesExist} element={<SharedLivingExpensesExistComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.SingleInsuranceTypeCheck} element={<SingleInsuranceTypeCheckComplexValidator />} />
                <Route path={RouteTable.Reference.ComplexValidators.VisaTypeExists} element={<VisaTypeExistsComplexValidator />} />

                <Route
                    element={
                        <RcPage>
                            <RcAlert icon="CloseCircle" variant="danger" title="The Page Could Not Be Found">
                                <p>What was here is either not here anymore or never was.</p>
                            </RcAlert>
                        </RcPage>
                    }
                />
            </Routes>
        );
    }

    function _toRoutes(): RcApplicationPageProps["navigation"] {
        // Make Menu Items
        function checkMenuActive(key: string) {
            if (location.pathname.includes(key)) return true;
            return false;
        }

        function makeSubItem(title: string, to: string, icon?: RcIconType, badge?: React.ReactNode): RcApplicationPageMenuSubItem {
            if (icon === undefined) {
                icon = "File";
            }
            return { key: title, title: title, icon: icon, badge: badge, to: to, active: location.pathname === to };
        }

        const getBadge = <RcBadge background="#008000">GET</RcBadge>;
        const postBadge = <RcBadge background="#FFA500">POST</RcBadge>;

        const menuItems: RcApplicationPageProps["navigation"] = [
            {
                items: [
                    {
                        title: "Getting started",
                        icon: "Home",
                        key: "GS",
                        active: location.pathname === RouteTable.Dashboard,
                        to: RouteTable.Dashboard,
                    },
                ],
                key: "GS",
            },
            {
                label: { title: "Applications" },
                key: "Applications",
                items: [
                    {
                        key: "Application Types",
                        icon: "Edit",
                        title: "Application Types",
                        subItems: [
                            makeSubItem("Individual", RouteTable.ApplicationType.Individual, "User"),
                            makeSubItem("Company", RouteTable.ApplicationType.Company, "Building"),
                            makeSubItem("Sole Trader", RouteTable.ApplicationType.SoleTrader, "User"),
                            makeSubItem("Partnership", RouteTable.ApplicationType.Partnership, "Group"),
                            makeSubItem("Trust w/ Individual Trustee", RouteTable.ApplicationType.TrustIndividual, "UserFollow"),
                            makeSubItem("Trust w/ Company Trustee", RouteTable.ApplicationType.TrustCompany, "UserAdd"),
                        ],
                        active: checkMenuActive(RouteTable.ApplicationType._root),
                    },
                    {
                        key: "Application Section Models",
                        icon: "Edit",
                        title: "Application Section Models",
                        subItems: [
                            makeSubItem("Loan", RouteTable.ApplicationSection.Loan, "Add"),
                            makeSubItem("Financial", RouteTable.ApplicationSection.Financial, "LineChart"),
                            makeSubItem("Security", RouteTable.ApplicationSection.Security, "Shield"),
                            makeSubItem("Household Expenses", RouteTable.ApplicationSection.HouseholdExpenses, "MoneyDollarCircle"),
                            makeSubItem("Additional Information", RouteTable.ApplicationSection.AdditionalInformation, "AlignLeft"),
                        ],
                        active: checkMenuActive(RouteTable.ApplicationSection._root),
                    },
                    {
                        key: "AppSubModels",
                        title: "Application Sub Models",
                        icon: "Edit2",
                        subItems: [
                            makeSubItem("All PAAP", RouteTable.ApplicationSubModels.AllPAAP, "Building"),
                            makeSubItem("Insurance", RouteTable.ApplicationSubModels.Insurances, "Lifebuoy"),
                            makeSubItem("Motorbike", RouteTable.ApplicationSubModels.Motorbike, "Bike"),
                            makeSubItem("Vehicle", RouteTable.ApplicationSubModels.MotorVehicle, "Car"),
                            makeSubItem("Other Goods", RouteTable.ApplicationSubModels.OtherGoods, "QuestionMark"),
                            makeSubItem("Recreational Asset", RouteTable.ApplicationSubModels.RecreationalAsset, "Truck"),
                            makeSubItem("Watercraft", RouteTable.ApplicationSubModels.Watercraft, "Sailboat"),
                        ],
                        active: checkMenuActive(RouteTable.ApplicationSubModels._root),
                    },
                ],
            },
            {
                label: { title: "Applicants" },
                key: "Applicants",
                items: [
                    {
                        title: "Applicant Types",
                        key: "AppliTypes",
                        icon: "User",
                        subItems: [
                            makeSubItem("Individual", RouteTable.ApplicantType.Individual, "User"),
                            makeSubItem("Commercial Entity", RouteTable.ApplicantType.CommercialEntity, "Building"),
                            makeSubItem("Sole Trader", RouteTable.ApplicantType.SoleTrader, "User"),
                            makeSubItem("Trustee Individual", RouteTable.ApplicantType.TrusteeIndividual, "User"),
                            makeSubItem("Trustee Company", RouteTable.ApplicantType.TrusteeCompany, "Building"),
                            makeSubItem("Guarantor", RouteTable.ApplicantType.Guarantor, "Shield"),
                        ],
                        active: checkMenuActive(RouteTable.ApplicantType._root),
                    },
                    {
                        title: "Applicant Section Models",
                        key: "AppliSecMod",
                        icon: "Group",
                        subItems: [
                            makeSubItem("Personal", RouteTable.ApplicantSection.Personal, "User"),
                            makeSubItem("Licence", RouteTable.ApplicantSection.Licence, "Licence"),
                            makeSubItem("Residential", RouteTable.ApplicantSection.Residential, "Home"),
                            makeSubItem("Sole Trader Employment", RouteTable.ApplicantSection.SoleTraderEmployment, "Briefcase"),
                            makeSubItem("Commercial Individual Employment", RouteTable.ApplicantSection.CommercialIndividualEmployment, "Briefcase"),
                            makeSubItem("Credit History", RouteTable.ApplicantSection.CreditHistory, "Time"),
                            makeSubItem("Entity", RouteTable.ApplicantSection.Entity, "Building"),
                            makeSubItem("Other", RouteTable.ApplicantSection.Other, "Edit"),
                            makeSubItem("Income", RouteTable.ApplicantSection.Income, "MoneyDollarCircle"),
                            makeSubItem("Expense", RouteTable.ApplicantSection.Expenses, "ShoppingBag"),
                            makeSubItem("Liabilities", RouteTable.ApplicantSection.Liabilities, "Flag"),
                        ],
                        active: checkMenuActive(RouteTable.ApplicantSection._root),
                    },
                    {
                        title: "Applicant Sub Models",
                        key: "AppliSubMod",
                        icon: "UserFollow",
                        subItems: [
                            makeSubItem("Credit Facility", RouteTable.ApplicantSubModels.CreditFacility, "Pulse"),
                            makeSubItem("Current Benefit", RouteTable.ApplicantSubModels.CurrentBenefit, "Add"),
                            makeSubItem("Current Employment", RouteTable.ApplicantSubModels.CurrentEmployment, "Briefcase"),
                            makeSubItem("Current Residential", RouteTable.ApplicantSubModels.CurrentResidential, "Building"),
                            makeSubItem("Current Self Employment", RouteTable.ApplicantSubModels.CurrentSelfEmployment, "UserAdd"),
                            makeSubItem("Current Standard Employment", RouteTable.ApplicantSubModels.CurrentStandardEmployment, "Briefcase"),
                            makeSubItem("Current Unemployment", RouteTable.ApplicantSubModels.CurrentUnemployed, "Close"),
                            makeSubItem("Entity", RouteTable.ApplicantSubModels.Entity, "Building"),
                            makeSubItem("General Liability", RouteTable.ApplicantSubModels.GeneralLiability, "Alert"),
                            makeSubItem("Marine Licence", RouteTable.ApplicantSubModels.MarineLicences, "Licence"),
                            makeSubItem("Postal Residential", RouteTable.ApplicantSubModels.PostalResidential, "FileText"),
                            makeSubItem("Previous Benefit", RouteTable.ApplicantSubModels.PreviousBenefit, "CameraLens"),
                            makeSubItem("Previous Employment", RouteTable.ApplicantSubModels.PreviousEmployment, "Briefcase"),
                            makeSubItem("Previous Residential", RouteTable.ApplicantSubModels.PreviousResidential, "Building"),
                            makeSubItem("Previous Self Employment", RouteTable.ApplicantSubModels.PreviousSelfEmployment, "User"),
                            makeSubItem("Previous Standard Employment", RouteTable.ApplicantSubModels.PreviousStandardEmployment, "Briefcase"),
                            makeSubItem("Previous Unemployment", RouteTable.ApplicantSubModels.PreviousUnemployed, "Close"),
                            makeSubItem("Vehicle Licence", RouteTable.ApplicantSubModels.VehicleLicences, "Licence"),
                        ],
                        active: checkMenuActive(RouteTable.ApplicantSubModels._root),
                    },
                ],
            },
            {
                label: { title: "Shared Models" },
                key: "SharedMod",
                items: [
                    {
                        title: "Address",
                        key: "Address",
                        icon: "Building",
                        to: RouteTable.SharedModel.Address,
                        active: checkMenuActive(RouteTable.SharedModel.Address),
                    },
                    {
                        title: "Contact Method",
                        key: "ContactMethod",
                        icon: "Phone",
                        to: RouteTable.SharedModel.ContactMethod,
                        active: checkMenuActive(RouteTable.SharedModel.ContactMethod),
                    },
                    {
                        title: "Point of Contact",
                        key: "PointOfContact",
                        icon: "Phone",
                        to: RouteTable.SharedModel.PointOfContact,
                        active: checkMenuActive(RouteTable.SharedModel.PointOfContact),
                    },
                    {
                        title: "Standard Employment",
                        key: "StandardEmployment",
                        icon: "Briefcase",
                        to: RouteTable.SharedModel.StandardEmployment,
                        active: checkMenuActive(RouteTable.SharedModel.StandardEmployment),
                    },
                    {
                        title: "Guarantor Employment",
                        key: "GuarantorEmployment",
                        icon: "Briefcase",
                        to: RouteTable.SharedModel.GuarantorEmployment,
                        active: checkMenuActive(RouteTable.SharedModel.GuarantorEmployment),
                    },
                ],
            },
            {
                label: { title: "HTTP Requests" },
                key: "HTTPRequests",
                items: [
                    {
                        key: "Applications",
                        icon: "File",
                        title: "Applications",
                        subItems: [
                            makeSubItem("Individual", RouteTable.HttpRequests.Applications.Individual, undefined, postBadge),
                            makeSubItem("Company", RouteTable.HttpRequests.Applications.Company, undefined, postBadge),
                            makeSubItem("Sole Trader", RouteTable.HttpRequests.Applications.SoleTrader, undefined, postBadge),
                            makeSubItem("Partnership", RouteTable.HttpRequests.Applications.Partnership, undefined, postBadge),
                            makeSubItem("Trust w/ Individual Trustee", RouteTable.HttpRequests.Applications.TrustIndividual, undefined, postBadge),
                            makeSubItem("Trust w/ Company Trustee", RouteTable.HttpRequests.Applications.TrustCompany, undefined, postBadge),
                        ],
                        active: checkMenuActive(RouteTable.HttpRequests.Applications._root),
                    },
                    {
                        key: "Documents",
                        icon: "File",
                        badge: postBadge,
                        title: "Documents",
                        to: RouteTable.HttpRequests.Documents,

                        active: checkMenuActive(RouteTable.HttpRequests.Documents),
                    },
                    {
                        key: "DynamicTypes",
                        title: "Dynamic Types",
                        icon: "File",
                        badge: getBadge,
                        to: RouteTable.HttpRequests.DynamicTypes,

                        active: checkMenuActive(RouteTable.HttpRequests.DynamicTypes),
                    },
                    {
                        key: "Permissions",
                        title: "Permissions",
                        icon: "File",
                        badge: getBadge,
                        to: RouteTable.HttpRequests.Permissions,

                        active: checkMenuActive(RouteTable.HttpRequests.Permissions),
                    },
                    {
                        key: "ProductTypes",
                        title: "Product Types",
                        icon: "File",
                        subItems: [
                            makeSubItem("Fetch All Products", RouteTable.HttpRequests.ProductTypes.FetchAllProducts, undefined, getBadge),
                            makeSubItem("Fetch Available Products", RouteTable.HttpRequests.ProductTypes.FetchAvailableProducts, undefined, postBadge),
                        ],
                        active: checkMenuActive(RouteTable.HttpRequests.ProductTypes._root),
                    },
                    {
                        key: "WhitelistedFiles",
                        title: "Whitelisted Files",
                        icon: "File",
                        badge: getBadge,
                        to: RouteTable.HttpRequests.WhitelistedFiles,

                        active: checkMenuActive(RouteTable.HttpRequests.WhitelistedFiles),
                    },
                ],
            },
            {
                label: { title: "Validation" },
                key: "Validation",
                items: [
                    {
                        title: "Complex Validation",
                        key: "ComplexVali",
                        icon: "CheckboxCircle",
                        subItems: [
                            makeSubItem("Benefit Income Exists", RouteTable.Reference.ComplexValidators.BenefitIncomeExists, "Check"),
                            makeSubItem("Circumstances Notes Exist", RouteTable.Reference.ComplexValidators.CircumstanceNotesExist, "Clipboard"),
                            makeSubItem("Current Unemployment Check", RouteTable.Reference.ComplexValidators.CurrentUnemploymentCheck, "Check"),
                            makeSubItem("Dependants Expenses Provided", RouteTable.Reference.ComplexValidators.DependantExpensesProvided, "FileAdd"),
                            makeSubItem("Employment History check", RouteTable.Reference.ComplexValidators.EmploymentHistoryCheck, "Check"),
                            makeSubItem("Employment Income Exists", RouteTable.Reference.ComplexValidators.EmploymentIncomeExists, "Clipboard"),
                            makeSubItem("Licence Fields Exist", RouteTable.Reference.ComplexValidators.LicenceFieldsExist, "Clipboard"),
                            makeSubItem("Other Income Has Comment", RouteTable.Reference.ComplexValidators.OtherIncomeHasComment, "Message"),
                            makeSubItem("Personal Contact Phone Exists", RouteTable.Reference.ComplexValidators.PersonalContactPhoneExists, "Clipboard"),
                            makeSubItem("Point of Contact Provided", RouteTable.Reference.ComplexValidators.PointOfContactProvided, "FileAdd"),
                            makeSubItem("Postal Address Provided if Required", RouteTable.Reference.ComplexValidators.PostalAddressProvidedIfRequired, "Mail"),
                            makeSubItem("Product Type Constraints", RouteTable.Reference.ComplexValidators.ProductTypeConstraints, "ListUnordered"),
                            makeSubItem("Renting Lease Individuals Provided", RouteTable.Reference.ComplexValidators.RentingLeaseIndividualsProvided, "FileAdd"),
                            makeSubItem("Residential History Check", RouteTable.Reference.ComplexValidators.ResidentialHistoryCheck, "Check"),
                            makeSubItem("Security Section Constraints", RouteTable.Reference.ComplexValidators.SecuritySectionConstraints, "Shield"),
                            makeSubItem("Shared Living Expenses Exist", RouteTable.Reference.ComplexValidators.SharedLivingExpensesExist, "Clipboard"),
                            makeSubItem("Single Insurance Type Check", RouteTable.Reference.ComplexValidators.SingleInsuranceTypeCheck, "Check"),
                            makeSubItem("Visa Type Exists", RouteTable.Reference.ComplexValidators.VisaTypeExists, "Clipboard"),
                        ],
                        active: checkMenuActive(RouteTable.Reference.ComplexValidators._root),
                    },
                ],
            },
            {
                label: { title: "References" },
                key: "References",
                items: [
                    {
                        title: "Dynamic Types",
                        key: "DynamicTypes",
                        icon: "ListUnordered",
                        subItems: [
                            makeSubItem("Benefit Types", RouteTable.Reference.DynamicTypes.Benefits, "Add"),
                            makeSubItem("Contact Methods", RouteTable.Reference.DynamicTypes.ContactMethods, "Phone"),
                            makeSubItem("Credit Facility Liabilities", RouteTable.Reference.DynamicTypes.CreditFacilityLiabilities, "BankCard"),
                            makeSubItem("Expense Types", RouteTable.Reference.DynamicTypes.Expenses, "ShoppingCart"),
                            makeSubItem("General Liabilities", RouteTable.Reference.DynamicTypes.GeneralLiability, "ShoppingCart"),
                            makeSubItem("Income Types", RouteTable.Reference.DynamicTypes.Incomes, "LineChart"),
                            makeSubItem("Insurance Types", RouteTable.Reference.DynamicTypes.Insurances, "Lifebuoy"),
                            makeSubItem("Loan Purposes", RouteTable.Reference.DynamicTypes.LoanPurposes, "Money"),
                            makeSubItem("Residential Statuses", RouteTable.Reference.DynamicTypes.Residential, "Building"),
                            makeSubItem("Residency Types", RouteTable.Reference.DynamicTypes.Residencies, "Building"),
                            makeSubItem("Visa Types", RouteTable.Reference.DynamicTypes.VisaTypes, "Licence"),
                        ],
                        active: checkMenuActive(RouteTable.Reference.DynamicTypes._root),
                    },
                    {
                        title: "Enumerated Values",
                        key: "EnumValues",
                        icon: "ListUnordered",
                        subItems: [
                            makeSubItem("Australian State", RouteTable.Reference.Enums.AustralianState, "Map"),
                            makeSubItem("Entity Type", RouteTable.Reference.Enums.EntityType, "Building"),
                            makeSubItem("Employment Basis", RouteTable.Reference.Enums.EmploymentBasis, "Briefcase"),
                            makeSubItem("Frequency", RouteTable.Reference.Enums.Frequency, "LineChart"),
                            makeSubItem("Sex", RouteTable.Reference.Enums.Sex, "User"),
                            makeSubItem("Title", RouteTable.Reference.Enums.Title),
                            makeSubItem("Marine Licence Class", RouteTable.Reference.Enums.MarineLicenceClass, "Licence"),
                            makeSubItem("Vehicle Licence Type", RouteTable.Reference.Enums.VehicleLicenceType, "Licence"),
                            makeSubItem("Marital Status", RouteTable.Reference.Enums.MaritalStatus, "Group"),
                            makeSubItem("Payment Preference", RouteTable.Reference.Enums.PaymentPreference, "Money"),
                            makeSubItem("Motorbike Type", RouteTable.Reference.Enums.MotorbikeType, "Bike"),
                            makeSubItem("Motor Vehicle Type", RouteTable.Reference.Enums.MotorVehicleType, "Car"),
                            makeSubItem("Recreational Asset Type", RouteTable.Reference.Enums.RecreationalAssetType, "Truck"),
                            makeSubItem("Watercraft", RouteTable.Reference.Enums.WatercraftType, "Sailboat"),
                        ],
                        active: checkMenuActive(RouteTable.Reference.Enums._root),
                    },
                    {
                        title: "Product Types",
                        key: "ProdTypes",
                        active: location.pathname === RouteTable.Reference.ProductType,
                        icon: "Package",
                        to: RouteTable.Reference.ProductType,
                    },
                ],
            },
        ];
        return menuItems;
    }

    return (
        <RcApplicationPage hideGradient commandBar={<ApplicationCommandBar />} navigation={_toRoutes()}>
            {_renderRouteTable()}
        </RcApplicationPage>
    );
};
