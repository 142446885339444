import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const ResidentialHistoryCheckComplexValidator: React.FC = () => {
    return (
        <RcPage title="Residential History Check" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that applicant’s residential history for the past 3 years is provided.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Residential}>ResidentialApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
