import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const PreviousStandardEmploymentsSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "duration",
            type: "int",
            validation: "Must be between 0, and 1,200",
            required: true,
            description: "The duration, in months, of the employment.",
        },
        {
            name: "endDate",
            type: "string",
            required: true,
            validation: "Date should be submitted in 'yyyy-MM-dd' format. Must be a date between 1 day, and 100 years old at the time of submission",
            description: "The date this employment was concluded.",
        },
        {
            name: "basis",
            type: "EnumEmploymentBasis",
            typeLink: RouteTable.Reference.Enums.EmploymentBasis,
            required: true,
            description: "The basis of this employment.",
        },
        {
            name: "occupation",
            type: "string",
            validation: "Must be between 2, and 100 characters in length",
            required: true,
            description: "The role the applicant was employed as.",
        },
        {
            name: "employerName",
            type: "string",
            validation: "Must be between 2, and 100 characters in length",
            required: true,
            description: "The name of the applicant's employer.",
        },
        {
            name: "contact",
            type: "PointOfContact",
            typeLink: RouteTable.SharedModel.PointOfContact,
            required: true,
            description: "The contact details of the applicant's employer reference.",
        },
        {
            name: "address",
            type: "Address",
            typeLink: RouteTable.SharedModel.Address,
            required: true,
            description: "The current address of this employer.",
        },
    ];
    return (
        <RcPage title="Previous Standard Employment" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PreviousEmployment}>PreviousEmploymentApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
