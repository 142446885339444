import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const FrequencyEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "weekly",
            description: "Weekly.",
        },
        {
            value: "fortnightly",
            description: "Fortnightly.",
        },
        {
            value: "monthly",
            description: "Monthly.",
        },
    ];
    return (
        <RcPage title="Frequency" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for the frequency of incoming and outgoing payments.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Income}>IncomeApplicantSection</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Expenses}>ExpenseApplicantSection</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationSection.Loan}>LoanApplicationSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
