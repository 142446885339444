import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const EmploymentIncomeExistsComplexValidator: React.FC = () => {
    return (
        <RcPage title="Employment Income Exists" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that at least one non-benefit type income is provided if one or more current standard or self-employment is listed on an applicant.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.SharedModel.StandardEmployment}>StandardEmploymentSharedModel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Income}>IncomeApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
