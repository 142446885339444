import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../manifold/RouteTable";
import { ValidatorReference } from "../../utils/ValidatorReference/ValidatorReference";

export const StandardEmploymentSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "current",
            type: "CurrentEmployments",
            typeLink: RouteTable.ApplicantSubModels.CurrentEmployment,
            required: true,
            description: "A summary of the applicant's current employments.",
        },
        {
            name: "previous",
            type: "PreviousEmployments",
            typeLink: RouteTable.ApplicantSubModels.PreviousEmployment,
            required: true,
            description: "A summary of the applicant's past 3 years of employment history.",
        },
    ];

    return (
        <RcPage title="Standard Employments" description="Shared Model">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Employment History Check Validator" link={RouteTable.Reference.ComplexValidators.EmploymentHistoryCheck}>
                    Applicants should provide their employment history for the last 3 years.
                </ValidatorReference>
                <ValidatorReference heading="Employment Income Exists Validator" link={RouteTable.Reference.ComplexValidators.EmploymentIncomeExists}>
                    If the applicant has provided one or more current standard or self employments, at least one non-benefit type income should also be provided.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.Individual}>IndividualApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.TrusteeIndividual}>TrusteeIndividualApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSection.SoleTraderEmployment}>SoleTraderEmploymentApplicantSection</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSection.CommercialIndividualEmployment}>CommercialIndividualEmploymentApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
