import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const EntityTypeEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "sole_trader",
            description: "Sole Trader.",
        },
        {
            value: "partnership",
            description: "Partnership.",
        },
        {
            value: "company",
            description: "Company.",
        },
        {
            value: "trust",
            description: "Trust.",
        },
    ];
    return (
        <RcPage title="Entity Type" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for entity types.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.Entity}>EntityApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
