export const IndividualTrustRequestBody = {
    uploadedDocuments: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    productTypeId: "COM.S.BRO",
    loan: {
        purpose: "motor_vehicle",
        term: 36,
        repaymentCapacity: 250,
        repaymentFrequency: "weekly",
        feePaymentPreference: "finance_under_contract",
        otherFeaturesOrRequirements: "Example other features and requirements text",
    },
    financial: {
        retailPrice: 10000,
        brokerageFee: 500,
        existingPayout: 0,
        deposit: 0,
        tradeIn: 0,
        insurances: [
            {
                type: "comprehensive",
                name: "Example Insurer",
                value: 1000,
            },
        ],
    },
    security: {
        preApproval: false,
        motorVehicles: [
            {
                make: "Holden",
                model: "Commodore",
                year: 2005,
                hasOdometer: true,
                odometer: 40000,
                isUnderFinance: false,
                vin: "12345678901234567",
                nvic: "123456",
                motorVehicleType: "sedan",
            },
        ],
        motorbikes: [],
        recreationalAssets: [],
        watercraft: [],
        allPAAP: [],
        otherGoods: [],
    },
    trust: {
        entity: {
            tradingName: "Example Trust Trading Name",
            abn: "51824753556",
            accountant: {
                name: "Example Accountant",
                phone: "0000000000",
            },
            address: {
                street: "1 Example St",
                suburb: "Example Suburb",
                state: "qld",
                postcode: "0000",
            },
            predominantBusinessActivity: "Example business activity",
        },
        incomes: [
            {
                type: "other_income",
                value: 500,
                frequency: "weekly",
                comment: "Example income description",
            },
        ],
        liabilities: {
            general: [
                {
                    type: "loan",
                    balance: 2500,
                    owedTo: "Example Lender",
                    monthlyPayment: 250,
                },
            ],
            creditFacilities: [
                {
                    type: "credit_card",
                    balance: 500,
                    owedTo: "Example Bank",
                    creditLimit: 3000,
                },
            ],
        },
        expenses: [
            {
                type: "other_expense",
                value: 500,
                frequency: "weekly",
            },
        ],
        creditHistory: {
            details: "Example credit history description",
        },
    },
    trustees: [
        {
            personal: {
                isExistingClient: false,
                title: "mr",
                firstName: "John",
                middleNames: "",
                lastName: "Smith",
                dateOfBirth: "1990-01-01",
                sex: "male",
                residencyStatus: "citizen",
                maritalStatus: "single",
                contactMethods: [
                    {
                        type: "mobile_phone",
                        value: "0000000000",
                    },
                ],
            },
            residential: {
                current: {
                    address: {
                        street: "1 Example St",
                        suburb: "Example Suburb",
                        state: "qld",
                        postcode: "0000",
                    },
                    duration: 18,
                    status: "owned_outright",
                    contact: null,
                },
                previous: [
                    {
                        address: {
                            street: "2 Example St",
                            suburb: "Example Suburb",
                            state: "qld",
                            postcode: "0000",
                        },
                        duration: 18,
                        endDate: "2018-01-01",
                        status: "renting",
                    },
                ],
                postal: {
                    sameAsCurrentAddress: true,
                    address: null,
                },
            },
            employment: {
                current: {
                    standard: [
                        {
                            duration: 18,
                            basis: "full_time",
                            occupation: "Example Occupation",
                            employerName: "Example Employer",
                            contact: {
                                name: "Example Employer Contact",
                                phone: "0000000000",
                                email: "test@financeone.com.au",
                            },
                            address: {
                                street: "1 Example St",
                                suburb: "Example Suburb",
                                state: "qld",
                                postcode: "0000",
                            },
                        },
                    ],
                    self: [],
                    unemployed: [],
                    benefits: [],
                },
                previous: {
                    standard: [
                        {
                            duration: 18,
                            endDate: "2018-01-01",
                            basis: "full_time",
                            occupation: "Example Occupation",
                            employerName: "Example Employer",
                            contact: {
                                name: "Example Employer Contact",
                                phone: "0000000000",
                            },
                            address: {
                                street: "1 Example St",
                                suburb: "Example Suburb",
                                state: "qld",
                                postcode: "0000",
                            },
                        },
                    ],
                    self: [],
                    unemployed: [],
                    benefits: [],
                },
            },
            creditHistory: {
                details: "Example credit history description",
            },
            other: {
                circumstancesChanging: false,
            },
        },
    ],
    additionalInformation: {
        notes: "Example additional information text",
    },
};

export const CompanyTrustRequestBody = {
    uploadedDocuments: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    productTypeId: "COM.S.BRO",
    loan: {
        purpose: "motor_vehicle",
        term: 36,
        repaymentCapacity: 250,
        repaymentFrequency: "weekly",
        feePaymentPreference: "finance_under_contract",
        otherFeaturesOrRequirements: "Example other features and requirements text",
    },
    financial: {
        retailPrice: 10000,
        brokerageFee: 500,
        existingPayout: 0,
        deposit: 0,
        tradeIn: 0,
        insurances: [
            {
                type: "comprehensive",
                name: "Example Insurer",
                value: 1000,
            },
        ],
    },
    security: {
        preApproval: false,
        motorVehicles: [
            {
                make: "Holden",
                model: "Commodore",
                year: 2005,
                hasOdometer: true,
                odometer: 40000,
                isUnderFinance: false,
                vin: "12345678901234567",
                nvic: "123456",
                motorVehicleType: "sedan",
            },
        ],
        motorbikes: [],
        recreationalAssets: [],
        watercraft: [],
        allPAAP: [],
        otherGoods: [],
    },
    trust: {
        entity: {
            tradingName: "Example Trust Trading Name",
            abn: "51824753556",
            accountant: {
                name: "Example Accountant",
                phone: "0000000000",
            },
            address: {
                street: "1 Example St",
                suburb: "Example Suburb",
                state: "qld",
                postcode: "0000",
            },
            predominantBusinessActivity: "Example business activity",
        },
        incomes: [
            {
                type: "other_income",
                value: 500,
                frequency: "weekly",
                comment: "Example income description",
            },
        ],
        liabilities: {
            general: [
                {
                    type: "loan",
                    balance: 2500,
                    owedTo: "Example Lender",
                    monthlyPayment: 250,
                },
            ],
            creditFacilities: [
                {
                    type: "credit_card",
                    balance: 500,
                    owedTo: "Example Bank",
                    creditLimit: 3000,
                },
            ],
        },
        expenses: [
            {
                type: "other_expense",
                value: 500,
                frequency: "weekly",
            },
        ],
        creditHistory: {
            details: "Example credit history description",
        },
    },
    trusteeEntity: {
        entity: {
            tradingName: "Example Trustee Entity Trading Name",
            abn: "51824753556",
            accountant: {
                name: "Example Accountant",
                phone: "0000000000",
            },
            address: {
                street: "1 Example St",
                suburb: "Example Suburb",
                state: "qld",
                postcode: "0000",
            },
            predominantBusinessActivity: "Example business activity",
        },
        creditHistory: {
            details: "Example credit history description",
        },
    },
    trusteeDirectors: [
        {
            personal: {
                isExistingClient: false,
                title: "mr",
                firstName: "John",
                middleNames: "",
                lastName: "Smith",
                dateOfBirth: "1990-01-01",
                sex: "male",
                residencyStatus: "citizen",
                maritalStatus: "single",
                contactMethods: [
                    {
                        type: "mobile_phone",
                        value: "0000000000",
                    },
                ],
            },
            residential: {
                current: {
                    address: {
                        street: "1 Example St",
                        suburb: "Example Suburb",
                        state: "qld",
                        postcode: "0000",
                    },
                    duration: 18,
                    status: "owned_outright",
                    contact: null,
                },
                previous: [
                    {
                        address: {
                            street: "2 Example St",
                            suburb: "Example Suburb",
                            state: "qld",
                            postcode: "0000",
                        },
                        duration: 18,
                        endDate: "2018-01-01",
                        status: "renting",
                    },
                ],
                postal: {
                    sameAsCurrentAddress: true,
                    address: null,
                },
            },
            employment: {
                guarantorEmployment: {
                    duration: 36,
                },
                standardEmployments: {
                    current: {
                        standard: [],
                        self: [],
                        unemployed: [],
                        benefits: [],
                    },
                    previous: {
                        standard: [],
                        self: [],
                        unemployed: [],
                        benefits: [],
                    },
                },
            },
            creditHistory: {
                details: "Example credit history description",
            },
            other: {
                circumstancesChanging: false,
            },
        },
    ],
    additionalInformation: {
        notes: "Example additional information text",
    },
};

export const IndividualRequestBody = {
    uploadedDocuments: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    productTypeId: "CON.S.BRO",
    loan: {
        purpose: "motor_vehicle",
        term: 36,
        repaymentCapacity: 250,
        repaymentFrequency: "weekly",
        feePaymentPreference: "finance_under_contract",
        otherFeaturesOrRequirements: "Example other features and requirements text",
    },
    financial: {
        retailPrice: 10000,
        brokerageFee: 500,
        existingPayout: 0,
        deposit: 2000,
        tradeIn: 0,
        insurances: [
            {
                type: "comprehensive",
                name: "Example Insurer",
                value: 1200,
            },
        ],
    },
    security: {
        preApproval: false,
        motorVehicles: [
            {
                make: "Holden",
                model: "Commodore",
                year: 2005,
                hasOdometer: true,
                odometer: 40000,
                isUnderFinance: false,
                vin: "12345678901234567",
                nvic: "123456",
                motorVehicleType: "sedan",
            },
        ],
        motorbikes: [],
        recreationalAssets: [],
        watercraft: [],
        allPAAP: [],
        otherGoods: [],
    },
    additionalInformation: {
        notes: "n/a",
    },
    householdExpenses: {
        livingExpenses: 1200,
        minorDependants: 0,
        adultDependants: 0,
        expensesIncludeDependants: true,
        sharesLivingExpenses: false,
        livingExpensePercentage: 100,
    },
    individuals: [
        {
            personal: {
                isExistingClient: false,
                title: "mr",
                firstName: "John",
                lastName: "Smith",
                dateOfBirth: "1990-01-01",
                sex: "male",
                residencyStatus: "citizen",
                maritalStatus: "single",
                contactMethods: [
                    {
                        type: "mobile_phone",
                        value: "0000000000",
                    },
                ],
            },
            residential: {
                current: {
                    address: {
                        street: "1 Example St",
                        suburb: "Example Suburb",
                        state: "qld",
                        postcode: "0000",
                    },
                    duration: 18,
                    status: "owned_outright",
                    numberOfIndividualsOnLease: 1,
                    contact: null,
                },
                previous: [
                    {
                        address: {
                            street: "2 Example St",
                            suburb: "Example Suburb",
                            state: "qld",
                            postcode: "0000",
                        },
                        duration: 18,
                        endDate: "2018-01-01",
                        status: "renting",
                    },
                ],
                postal: {
                    sameAsCurrentAddress: true,
                    address: null,
                },
            },
            licence: {
                isLicenceProvided: true,
                hasSpecialRestrictions: false,
                vehicleLicences: [
                    {
                        number: "000000000",
                        state: "qld",
                        expirationDate: "2022-01-01",
                        licenceType: "open",
                    },
                ],
                marineLicences: [],
            },
            employment: {
                current: {
                    standard: [
                        {
                            duration: 71,
                            basis: "full_time",
                            occupation: "Example Occuption",
                            employerName: "Example Employer Name",
                            contact: {
                                name: "Example Contact Name",
                                phone: "000000000",
                            },
                            address: {
                                street: "1 Example St",
                                suburb: "Example Suburb",
                                state: "qld",
                                postcode: "0000",
                            },
                        },
                    ],
                    self: [],
                    unemployed: [],
                    benefits: [],
                },
                previous: {
                    standard: [],
                    self: [],
                    unemployed: [],
                    benefits: [],
                },
            },
            creditHistory: {
                details: "Example credit history description",
            },
            incomes: [
                {
                    type: "payg_income",
                    value: 1000,
                    frequency: "weekly",
                },
            ],
            liabilities: {
                general: [
                    {
                        type: "loan",
                        balance: 500,
                        owedTo: "Example Bank",
                        monthlyPayment: 250,
                    },
                ],
                creditFacilities: [],
            },
            expenses: [
                {
                    type: "other_expense",
                    value: 300,
                    frequency: "weekly",
                },
            ],
            other: {
                circumstancesChanging: false,
            },
        },
    ],
};

export const CompanyRequestBody = {
    uploadedDocuments: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    productTypeId: "COM.S.BRO",
    loan: {
        purpose: "motor_vehicle",
        term: 36,
        repaymentCapacity: 250,
        repaymentFrequency: "weekly",
        feePaymentPreference: "finance_under_contract",
        otherFeaturesOrRequirements: "Example other features and requirements text",
    },
    financial: {
        retailPrice: 11000,
        brokerageFee: 500,
        existingPayout: 0,
        deposit: 0,
        tradeIn: 0,
        insurances: [
            {
                type: "comprehensive",
                name: "Example Insurer",
                value: 1000,
            },
        ],
    },
    security: {
        preApproval: false,
        motorVehicles: [
            {
                make: "Holden",
                model: "Commodore",
                year: 2005,
                hasOdometer: true,
                odometer: 40000,
                isUnderFinance: false,
                vin: "12345678901234567",
                nvic: "123456",
                motorVehicleType: "sedan",
            },
        ],
        motorbikes: [],
        recreationalAssets: [],
        watercraft: [],
        allPAAP: [],
        otherGoods: [],
    },
    company: {
        entity: {
            tradingName: "Example Entity Trading Name",
            abn: "51824753556",
            accountant: {
                name: "Example Accountant",
                phone: "0000000000",
            },
            address: {
                street: "1 Example St",
                suburb: "Example Suburb",
                state: "qld",
                postcode: "0000",
            },
            predominantBusinessActivity: "Example business activity",
        },
        incomes: [
            {
                type: "other_income",
                value: 500,
                frequency: "weekly",
                comment: "Example income description",
            },
        ],
        liabilities: {
            general: [
                {
                    type: "loan",
                    balance: 2500,
                    owedTo: "Example Lender",
                    monthlyPayment: 250,
                },
            ],
            creditFacilities: [
                {
                    type: "credit_card",
                    balance: 500,
                    owedTo: "Example Bank",
                    creditLimit: 3000,
                },
            ],
        },
        expenses: [
            {
                type: "other_expense",
                value: 500,
                frequency: "weekly",
            },
        ],
        creditHistory: {
            details: "Example credit history description",
        },
    },
    directors: [
        {
            personal: {
                isExistingClient: false,
                title: "mr",
                firstName: "John",
                middleNames: "",
                lastName: "Smith",
                dateOfBirth: "1990-01-01",
                sex: "male",
                residencyStatus: "citizen",
                maritalStatus: "single",
                contactMethods: [
                    {
                        type: "mobile_phone",
                        value: "0000000000",
                    },
                ],
            },
            residential: {
                current: {
                    address: {
                        street: "1 Example St",
                        suburb: "Example Suburb",
                        state: "qld",
                        postcode: "0000",
                    },
                    duration: 18,
                    status: "owned_outright",
                    contact: null,
                },
                previous: [
                    {
                        address: {
                            street: "2 Example St",
                            suburb: "Example Suburb",
                            state: "qld",
                            postcode: "0000",
                        },
                        duration: 18,
                        endDate: "2018-01-01",
                        status: "renting",
                    },
                ],
                postal: {
                    sameAsCurrentAddress: true,
                    address: null,
                },
            },
            employment: {
                guarantorEmployment: {
                    duration: 36,
                },
                standardEmployments: {
                    current: {
                        standard: [],
                        self: [],
                        unemployed: [],
                        benefits: [],
                    },
                    previous: {
                        standard: [],
                        self: [],
                        unemployed: [],
                        benefits: [],
                    },
                },
            },
            creditHistory: {
                details: "Example credit history description",
            },
            other: {
                circumstancesChanging: false,
            },
        },
    ],
    additionalInformation: {
        notes: "Example additional information text",
    },
};

export const PartnershipRequestBody = {
    uploadedDocuments: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    productTypeId: "COM.S.BRO",
    loan: {
        purpose: "motor_vehicle",
        term: 36,
        repaymentCapacity: 250,
        repaymentFrequency: "weekly",
        feePaymentPreference: "finance_under_contract",
        otherFeaturesOrRequirements: "Example other features and requirements text",
    },
    financial: {
        retailPrice: 11000,
        brokerageFee: 500,
        existingPayout: 0,
        deposit: 0,
        tradeIn: 0,
        insurances: [
            {
                type: "comprehensive",
                name: "Example Insurer",
                value: 1000,
            },
        ],
    },
    security: {
        preApproval: false,
        motorVehicles: [
            {
                make: "Holden",
                model: "Commodore",
                year: 2005,
                hasOdometer: true,
                odometer: 40000,
                isUnderFinance: false,
                vin: "12345678901234567",
                nvic: "123456",
                motorVehicleType: "sedan",
            },
        ],
        motorbikes: [],
        recreationalAssets: [],
        watercraft: [],
        allPAAP: [],
        otherGoods: [],
    },
    commercialEntity: {
        entity: {
            tradingName: "Example Partnership Entity Trading Name",
            abn: "51824753556",
            accountant: {
                name: "Example Accountant",
                phone: "0000000000",
            },
            address: {
                street: "1 Example St",
                suburb: "Example Suburb",
                state: "qld",
                postcode: "0000",
            },
            predominantBusinessActivity: "Example business activity",
        },
        incomes: [
            {
                type: "other_income",
                value: 500,
                frequency: "weekly",
                comment: "Example income description",
            },
        ],
        liabilities: {
            general: [
                {
                    type: "loan",
                    balance: 2500,
                    owedTo: "Example Lender",
                    monthlyPayment: 250,
                },
            ],
            creditFacilities: [
                {
                    type: "credit_card",
                    balance: 500,
                    owedTo: "Example Bank",
                    creditLimit: 3000,
                },
            ],
        },
        expenses: [
            {
                type: "other_expense",
                value: 500,
                frequency: "weekly",
            },
        ],
        creditHistory: {
            details: "Example credit history description",
        },
    },
    partners: [
        {
            personal: {
                isExistingClient: false,
                title: "mr",
                firstName: "John",
                middleNames: "",
                lastName: "Smith",
                dateOfBirth: "1990-01-01",
                sex: "male",
                residencyStatus: "citizen",
                maritalStatus: "single",
                contactMethods: [
                    {
                        type: "mobile_phone",
                        value: "0000000000",
                    },
                ],
            },
            residential: {
                current: {
                    address: {
                        street: "1 Example St",
                        suburb: "Example Suburb",
                        state: "qld",
                        postcode: "0000",
                    },
                    duration: 18,
                    status: "owned_outright",
                    contact: null,
                },
                previous: [
                    {
                        address: {
                            street: "2 Example St",
                            suburb: "Example Suburb",
                            state: "qld",
                            postcode: "0000",
                        },
                        duration: 18,
                        endDate: "2018-01-01",
                        status: "renting",
                    },
                ],
                postal: {
                    sameAsCurrentAddress: true,
                    address: null,
                },
            },
            employment: {
                guarantorEmployment: {
                    duration: 36,
                },
                standardEmployments: {
                    current: {
                        standard: [],
                        self: [],
                        unemployed: [],
                        benefits: [],
                    },
                    previous: {
                        standard: [],
                        self: [],
                        unemployed: [],
                        benefits: [],
                    },
                },
            },
            creditHistory: {
                details: "Example credit history description",
            },
            other: {
                circumstancesChanging: false,
            },
        },
        {
            personal: {
                isExistingClient: false,
                title: "ms",
                firstName: "Jane",
                middleNames: "",
                lastName: "Smith",
                dateOfBirth: "1990-01-01",
                sex: "female",
                residencyStatus: "citizen",
                maritalStatus: "single",
                contactMethods: [
                    {
                        type: "mobile_phone",
                        value: "0000000000",
                    },
                ],
            },
            residential: {
                current: {
                    address: {
                        street: "1 Example St",
                        suburb: "Example Suburb",
                        state: "qld",
                        postcode: "0000",
                    },
                    duration: 18,
                    status: "owned_outright",
                    contact: null,
                },
                previous: [
                    {
                        address: {
                            street: "2 Example St",
                            suburb: "Example Suburb",
                            state: "qld",
                            postcode: "0000",
                        },
                        duration: 18,
                        endDate: "2018-01-01",
                        status: "renting",
                    },
                ],
                postal: {
                    sameAsCurrentAddress: true,
                    address: null,
                },
            },
            employment: {
                guarantorEmployment: {
                    duration: 36,
                },
                standardEmployments: {
                    current: {
                        standard: [],
                        self: [],
                        unemployed: [],
                        benefits: [],
                    },
                    previous: {
                        standard: [],
                        self: [],
                        unemployed: [],
                        benefits: [],
                    },
                },
            },
            creditHistory: {
                details: "Example credit history description",
            },
            other: {
                circumstancesChanging: false,
            },
        },
    ],
    additionalInformation: {
        notes: "Example additional information text",
    },
};

export const SoleTraderRequestBody = {
    uploadedDocuments: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    productTypeId: "COM.S.GOL",
    loan: {
        purpose: "motor_vehicle",
        term: 36,
        repaymentCapacity: 250,
        repaymentFrequency: "weekly",
        feePaymentPreference: "finance_under_contract",
        otherFeaturesOrRequirements: "Example other features and requirements text",
    },
    financial: {
        retailPrice: 10000,
        brokerageFee: 500,
        existingPayout: 0,
        deposit: 2000,
        tradeIn: 0,
        insurances: [
            {
                type: "comprehensive",
                name: "Example Insurer",
                value: 1200,
            },
        ],
    },
    security: {
        preApproval: false,
        motorVehicles: [
            {
                make: "Holden",
                model: "Commodore",
                year: 2005,
                hasOdometer: true,
                odometer: 40000,
                isUnderFinance: false,
                vin: "12345678901234567",
                nvic: "123456",
                motorVehicleType: "sedan",
            },
        ],
        motorbikes: [],
        recreationalAssets: [],
        watercraft: [],
        allPAAP: [],
        otherGoods: [],
    },
    householdExpenses: {
        livingExpenses: 1900,
        minorDependants: 1,
        adultDependants: 1,
        expensesIncludeDependants: false,
        minorExpenses: 1400,
        adultExpenses: 2100,
        sharesLivingExpenses: true,
        livingExpensePercentage: 50,
    },
    soleTrader: {
        personal: {
            isExistingClient: false,
            title: "mr",
            firstName: "John",
            middleNames: "",
            lastName: "Smith",
            dateOfBirth: "1990-01-01",
            sex: "male",
            residencyStatus: "citizen",
            maritalStatus: "married",
            contactMethods: [
                {
                    type: "mobile_phone",
                    value: "0000000000",
                },
            ],
        },
        residential: {
            current: {
                address: {
                    street: "1 Example St",
                    suburb: "Example Suburb",
                    state: "qld",
                    postcode: "0000",
                },
                duration: 18,
                status: "renting",
                contact: {
                    name: "Example Landlord",
                    phone: "0000000000",
                    email: "example@financeone.com.au",
                },
                numberOfIndividualsOnLease: 1,
            },
            previous: [
                {
                    address: {
                        street: "2 Example St",
                        suburb: "Example Suburb",
                        state: "qld",
                        postcode: "4812",
                    },
                    duration: 48,
                    endDate: "01/10/2017",
                    status: "boarding",
                },
            ],
            postal: {
                sameAsCurrentAddress: false,
                address: {
                    street: "3 Example St",
                    suburb: "Example Suburb",
                    state: "qld",
                    postcode: "4812",
                },
            },
        },
        licence: {
            isLicenceProvided: true,
            hasSpecialRestrictions: true,
            specialRestrictionsDetails: "Customer requires glasses to drive.",
            vehicleLicences: [
                {
                    number: "0000000000",
                    state: "qld",
                    expirationDate: "2025-01-01",
                    licenceType: "open",
                },
            ],
            marineLicences: [],
        },
        employment: {
            guarantorEmployment: {
                duration: 36,
            },
            receivesPaygIncome: false,
            standardEmployments: {
                current: {
                    standard: [],
                    self: [],
                    unemployed: [],
                    benefits: [],
                },
                previous: {
                    standard: [],
                    self: [],
                    unemployed: [],
                    benefits: [],
                },
            },
        },
        creditHistory: {
            details: "Example credit history description",
        },
        incomes: [
            {
                type: "self_employed_income",
                value: 1000,
                frequency: "weekly",
            },
        ],
        liabilities: {
            general: [
                {
                    type: "loan",
                    balance: 2500,
                    owedTo: "Example Lender",
                    monthlyPayment: 250,
                },
            ],
            creditFacilities: [
                {
                    type: "credit_card",
                    balance: 500,
                    owedTo: "Example Bank",
                    creditLimit: 3000,
                },
            ],
        },
        expenses: [
            {
                type: "other_expense",
                value: 300,
                frequency: "weekly",
            },
        ],
        other: {
            circumstancesChanging: true,
            changeInformation: "Example expected change in circumstances text",
        },
        entity: {
            tradingName: "Example Sole Trader Trading Name",
            abn: "51824753556",
            accountant: {
                name: "Example Accountant",
                phone: "0000000000",
            },
            address: {
                street: "1 Example St",
                suburb: "Example Suburb",
                state: "qld",
                postcode: "0000",
            },
            predominantBusinessActivity: "Example business activity",
        },
    },
    additionalInformation: {
        notes: "Example additional information text",
    },
};

export const AvailableProductTypesRequestBody = {
    brokerId: "BE-EXAMPLE",
};
