import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const IncomeApplicantSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "type",
            type: "DynamicIncome",
            typeLink: RouteTable.Reference.DynamicTypes.Incomes,
            required: true,
            description: "The type of income source.",
        },
        {
            name: "value",
            type: "decimal",
            validation: "Must be between 0, and 10,000,000",
            required: true,
            description: "The value of the income.",
        },
        {
            name: "frequency",
            type: "EnumFrequency",
            typeLink: RouteTable.Reference.Enums.Frequency,
            required: true,
            description: "The frequency of the income.",
        },
        {
            name: "comment",
            type: "string",
            required: "This is required if type is set to 'other_income'.",
            description: "A short comment describing the income source.",
        },
    ];

    return (
        <RcPage title="Income Section" description="Applicant Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Benefit Income Exists Validator" link={RouteTable.Reference.ComplexValidators.BenefitIncomeExists}>
                    Current Benefit employments should have a corresponding benefit income type.
                </ValidatorReference>
                <ValidatorReference heading="Employment Income Exists Validator" link={RouteTable.Reference.ComplexValidators.EmploymentIncomeExists}>
                    If the applicant has provided one or more current standard or self employments, at least one non-benefit type income should also be provided.
                </ValidatorReference>
                <ValidatorReference heading="Other Income Has Comment Validator" link={RouteTable.Reference.ComplexValidators.OtherIncomeHasComment}>
                    If an income has its type set as "other_income", a comment should be provided giving a description of the source.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.Individual}>IndividualApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.SoleTrader}>SoleTraderApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.CommercialEntity}>CommercialEntityApplicantType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
