import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const AustralianStateEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "act",
            description: "Australian Capital Territory.",
        },
        {
            value: "nsw",
            description: "New South Wales.",
        },
        {
            value: "vic",
            description: "Victoria.",
        },
        {
            value: "qld",
            description: "Queensland.",
        },
        {
            value: "sa",
            description: "South Australia.",
        },
        {
            value: "wa",
            description: "Western Australia.",
        },
        {
            value: "tas",
            description: "Tasmania.",
        },
        {
            value: "nt",
            description: "Northern Territory.",
        },
        {
            value: "outside_australia",
            description: "Outside Australia.",
        },
    ];
    return (
        <RcPage title="State" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for Australian states.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.SharedModel.Address}>AddressSharedModel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.VehicleLicences}>VehicleLicenceApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.MarineLicences}>MarineLicenceApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
