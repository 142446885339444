import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const CircumstanceNotesExistComplexValidator: React.FC = () => {
    return (
        <RcPage title="Circumstance Notes Exist" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that the details of an applicant’s expected changing circumstances have been provided if they have indicated that they are expecting changes.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Other}>OtherApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
