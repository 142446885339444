import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const CreditFacilitySharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "type",
            type: "DynamicCreditFacilityType",
            typeLink: RouteTable.Reference.DynamicTypes.CreditFacilityLiabilities,
            required: true,
            description: "The credit facility liability type.",
        },
        {
            name: "balance",
            type: "decimal",
            required: true,
            description: "The current balance of the credit facility.",
        },
        {
            name: "owedTo",
            type: "string",
            validation: "Must be between 2, and 50 characters in length",
            required: true,
            description: "The name of the entity the credit facility is owed to.",
        },
        {
            name: "creditLimit",
            type: "decimal",
            validation: "Must be between 0, and 10,000,000",
            required: true,
            description: "The credit limit of the credit facility.",
        },
    ];

    return (
        <RcPage title="Credit Facility" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Liabilities}>LiabilitiesApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
