import { ApplicationRoutes } from "./AppParts/AppRoutes";
import { RcApplication } from "@financeone/core-ui";
import React from "react";

export const App: React.FC = () => {
    return (
        <RcApplication>
            <ApplicationRoutes />
        </RcApplication>
    );
};
