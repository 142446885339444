import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const CurrentUnemploymentCheckComplexValidator: React.FC = () => {
    return (
        <RcPage title="Current Unemployment Check" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that an applicant is not listed as currently unemployed, while also having current employments/benefits.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentEmployment}>CurrentEmploymentApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
