import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const LicenceFieldsExistComplexValidator: React.FC = () => {
    return (
        <RcPage title="Licence Fields Exist" description="Complex Validator">
            <RcSection title="Purpose">
                <p>
                    Ensures that the licence section is filled out correctly. If a licence is not provided, a reason for not providing should be supplied. And if the provided licence has special restrictions, the details of those restrictions should be given.
                </p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Licence}>LicenceApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
