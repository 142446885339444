import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const PaymentPreferenceEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "finance_under_contract",
            description: "The applicant wishes to finance the fees under the loan contract.",
        },
        {
            value: "pay_upfront",
            description: "The applicant wishes to pay all their fees upfront.",
        },
    ];
    return (
        <RcPage title="Payment Preference" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for fee payment preference options.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSection.Loan}>LoanApplicationSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
