import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const VehicleLicenceSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "number",
            type: "string",
            validation: "Must be between 1, and 14 characters in length",
            required: true,
            description: "The licence number of the vehicle licence.",
        },
        {
            name: "state",
            type: "EnumState",
            typeLink: RouteTable.Reference.Enums.AustralianState,
            required: true,
            description: "The issuing state of the vehicle licence.",
        },
        {
            name: "expirationDate",
            type: "string",
            required: true,
            validation: "Date should be submitted in 'yyyy-MM-dd' format. Must be a date between 1 day, and 20 years from the date of submission",
            description: "The expiration date of the vehicle licence.",
        },
        {
            name: "licenceType",
            type: "EnumVehicleLicenceType",
            typeLink: RouteTable.Reference.Enums.VehicleLicenceType,
            required: true,
            description: "The licence type of the vehicle licence.",
        },
    ];
    return (
        <RcPage title="Vehicle Licence" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Licence}>LicenceApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
