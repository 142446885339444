import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const PostalAddressProvidedIfRequiredComplexValidator: React.FC = () => {
    return (
        <RcPage title="Postal Address Provided if Required" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that a postal address is provided, if it is indicated that the applicant’s postal address is different to their current residential address.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PostalResidential}>PostalResidentialApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
