export const GenericApplicationResponseBody = {
    message: "The request was successful",
    data: {
        accountId: "000001",
    },
};

export const DocumentUploadResponseBody = {
    message: "The request was successful",
    data: {
        uuid: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    },
};

export const DynamicTypesResponseBody = {
    message: "The request was successful",
    data: {
        benefits: [
            {
                type: "benefit_aged_pension",
                name: "Aged Pension",
                description: "",
                audience: "consumer",
            },
            {
                type: "benefit_family_tax",
                name: "Parenting Payments / Family Tax",
                description: "",
                audience: "consumer",
            },
        ],
        contactMethods: [
            {
                type: "home_phone",
                name: "Home Phone",
                description: "",
                audience: "all",
            },
            {
                type: "mobile_phone",
                name: "Mobile Phone",
                description: "",
                audience: "all",
            },
        ],
        creditFacilityLiabilities: [
            {
                type: "credit_card",
                name: "Credit Card",
                description: "",
                audience: "all",
            },
            {
                type: "overdraft",
                name: "Overdraft",
                description: "",
                audience: "all",
            },
        ],
        expenses: [
            {
                type: "rent",
                name: "Rent",
                description: "",
                audience: "all",
            },
            {
                type: "living_expenses",
                name: "Living Expenses",
                description: "",
                audience: "all",
            },
        ],
        incomes: [
            {
                type: "other_income",
                name: "Other Income",
                description: "",
                audience: "all",
            },
            {
                type: "business_income",
                name: "Business Income",
                description: "",
                audience: "commercial",
            },
        ],
        insurances: [
            {
                type: "comprehensive",
                name: "Comprehensive Insurance",
                description: "",
                audience: "all",
            },
            {
                type: "gap",
                name: "Gap Insurance",
                description: "",
                audience: "all",
            },
        ],
        loanPurposes: [
            {
                type: "motor_vehicle",
                name: "Motor Vehicle",
                description: "",
                audience: "all",
            },
            {
                type: "watercraft",
                name: "Watercraft",
                description: "",
                audience: "all",
            },
        ],
        residential: [
            {
                type: "renting",
                name: "Renting",
                description: "",
                audience: "all",
            },
            {
                type: "boarding",
                name: "Boarding",
                description: "",
                audience: "consumer",
            },
        ],
        residencies: [
            {
                type: "visa",
                name: "Visa",
                description: "",
                audience: "all",
            },
            {
                type: "citizen",
                name: "Citizen",
                description: "",
                audience: "all",
            },
        ],
        standardLiabilities: [
            {
                type: "mortgage",
                name: "Mortgage",
                description: "",
                audience: "all",
            },
            {
                type: "motor_vehicle",
                name: "Motor Vehicle",
                description: "",
                audience: "all",
            },
        ],
        visaTypes: [
            {
                type: "not_listed",
                name: "Not Listed",
                description: "",
                audience: "all",
            },
            {
                type: "permananent_partner_visa_migrant_100",
                name: "Permanent Partner Visa Migrant 100",
                description: "",
                audience: "all",
            },
        ],
    },
};

export const PermissionsResponseBody = {
    message: "The request was successful",
    data: [
        {
            name: "Smith, John",
            brokerId: "BE-JSMIT",
        },
        {
            name: "Brown, James",
            brokerId: "BE-JBROW",
        },
    ],
};

export const AllProductTypesResponseBody = {
    message: "The request was successful",
    data: [
        {
            id: "COM.EXAMPLE",
            productClassName: "Commercial",
            name: "Commercial Example",
            keyDetails: {
                lend: {
                    minimum: 5000,
                    maximum: 50000,
                },
                term: {
                    minimum: 36,
                    maximum: 72,
                },
                interest: {
                    minimum: 20,
                    maximum: 25,
                },
            },
            restrictions: {
                individualsAllowed: false,
                companiesAllowed: true,
                isSecured: true,
            },
            requiredDocuments: [
                {
                    key: "example_document",
                    name: "Example Document",
                },
            ],
        },
        {
            id: "CON.EXAMPLE",
            productClassName: "Consumer",
            name: "Consumer Example",
            keyDetails: {
                lend: {
                    minimum: 5000,
                    maximum: 50000,
                },
                term: {
                    minimum: 36,
                    maximum: 72,
                },
                interest: {
                    minimum: 20,
                    maximum: 25,
                },
            },
            restrictions: {
                individualsAllowed: true,
                companiesAllowed: false,
                isSecured: true,
            },
            requiredDocuments: [
                {
                    key: "example_document",
                    name: "Example Document",
                },
            ],
        },
    ],
};

export const AvailableProductTypesResponseBody = {
    message: "The request was successful",
    data: [
        {
            id: "CON.EXAMPLE",
            productClassName: "Consumer",
            name: "Consumer Example",
            keyDetails: {
                lend: {
                    minimum: 5000,
                    maximum: 50000,
                },
                term: {
                    minimum: 36,
                    maximum: 72,
                },
                interest: {
                    minimum: 20,
                    maximum: 25,
                },
            },
            restrictions: {
                individualsAllowed: true,
                companiesAllowed: false,
                isSecured: true,
            },
            requiredDocuments: [
                {
                    key: "example_document",
                    name: "Example Document",
                },
            ],
        },
    ],
};

export const WhitelistedFilesResponseBody = {
    message: "The request was successful",
    data: [
        {
            description: "Word Document",
            contentType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        },
        {
            description: "Word Document",
            contentType: "application/msword",
        },
        {
            description: "PDF Document",
            contentType: "application/pdf",
        },
        {
            description: "PNG Image",
            contentType: "image/png",
        },
        {
            description: "PNG Image",
            contentType: "image/x-citrix-png",
        },
        {
            description: "PNG Image",
            contentType: "image/x-png",
        },
        {
            description: "JPEG Image",
            contentType: "image/jpeg",
        },
        {
            description: "JPEG Image",
            contentType: "image/x-citrix-jpeg",
        },
        {
            description: "JPEG Image",
            contentType: "image/pjpeg",
        },
        {
            description: "GIF Image",
            contentType: "image/gif",
        },
    ],
};
