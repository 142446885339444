import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const FinancialApplicationSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "retailPrice",
            type: "decimal",
            required: true,
            description: "The retail value of the security item.",
            validation: "Must be between 0, and 10,000,000",
        },
        {
            name: "brokerageFee",
            type: "decimal",
            required: true,
            description: "This reflects as brokerage fee in consumer applications, and as the origination fee in commercial applications.",
            validation: "Must be between 0, and 1,100",
        },
        {
            name: "existingPayout",
            type: "decimal",
            required: true,
            description: "The payout value of the applicant’s pre-existing loan.",
            validation: "Must be between 0, and 10,000,000",
        },
        {
            name: "deposit",
            type: "decimal",
            required: true,
            description: "The value of any deposits the applicant will make towards the security item.",
            validation: "Must be between 0, and 10,000,000",
        },
        {
            name: "tradeIn",
            type: "decimal",
            required: true,
            description: "The trade in value of any existing vehicles the applicant plans to put forward.",
            validation: "Must be between 0, and 10,000,000",
        },
        {
            name: "interestRate",
            type: "double",
            required: false,
            description: "The interest rate of the application. If it is not defined or provided, it will default to the max interest rate of the declared product type.",
            validation: "Must be between 0, and 100",
        },
        {
            name: "insurances",
            type: "Insurance[]",
            typeLink: RouteTable.ApplicationSubModels.Insurances,
            required: true,
            description: "A list of insurance policies the applicant will be adding to the proposed loan.",
        },
    ];

    return (
        <RcPage title="Financial Section" description="Application Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Single Insurance Type Check Validator" link={RouteTable.Reference.ComplexValidators.SingleInsuranceTypeCheck}>
                    Only one insurance policy of each type should be provided.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.Individual}>IndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.SoleTrader}>SoleTraderApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Company}>CompanyApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Partnership}>PartnershipApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustIndividual}>TrustIndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustCompany}>TrustCompanyApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
