import "./CodeBlock.scss";

import { RcButton, RcButtonGroup } from "@financeone/core-ui";
import React, { useRef, useState } from "react";

import SyntaxHighlighter from "react-syntax-highlighter";

interface Props {
    code: object;
    language?: string;
}

const minimisedCodeLineCount = 20;

export const CodeBlock: React.FunctionComponent<Props> = ({ language = "json", code }) => {
    const [showFullCode, setShowFullCode] = useState(lineCount(getJsonString()) <= minimisedCodeLineCount);
    const divRef = useRef<HTMLDivElement>(null);

    let jsonString = getJsonString();

    if (!showFullCode) {
        jsonString = truncateToLineCount(jsonString, minimisedCodeLineCount);
    }

    function lineCount(input: string): number {
        return input.split("\n").length;
    }

    function getJsonString(): string {
        return JSON.stringify(code, undefined, 2);
    }

    function truncateToLineCount(input: string, outputLineCount: number) {
        const lines = input.split("\n");
        let output = "";

        let cnt = 0;
        for (const line of lines) {
            output += line + "\n";

            cnt++;
            if (cnt > outputLineCount) break;
        }

        return output;
    }

    function changeViewButtonClick() {
        if (divRef.current != null) {
            if (showFullCode) {
                // Scroll to top of code block when collapsing to "Show less" view
                const divRect = divRef.current.getBoundingClientRect();

                if (divRect.top < 0) {
                    // Only scroll to top of code block if top of code block div is above the browser viewport
                    divRef.current.scrollIntoView();
                }
            }
        }
        setShowFullCode(!showFullCode);
    }

    return (
        <div className="code-block" ref={divRef}>
            <SyntaxHighlighter language={language} useInlineStyles={false} showLineNumbers>
                {jsonString}
            </SyntaxHighlighter>
            {lineCount(getJsonString()) > minimisedCodeLineCount && (
                <RcButtonGroup>
                    <RcButton small fill="empty" variant="neutral" onClick={() => changeViewButtonClick()} icon={showFullCode ? "ArrowUpS" : "ArrowDownS"} />
                </RcButtonGroup>
            )}
        </div>
    );
};
