import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const AllPAAPSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "abn",
            type: "string",
            required: false,
            description: "The ABN of the entity to be registered as collateral.",
            validation: "Must be a valid ABN number",
        },
        {
            name: "acn",
            type: "string",
            required: false,
            description: "The ACN of the entity to be registered as collateral.",
            validation: "Must be a valid ACN number",
        },
        {
            name: "exceptions",
            type: "boolean",
            required: true,
            description: "Indicates whether the registration of collateral will have any exceptions.",
        },
    ];
    return (
        <RcPage title="AllPAAP" description="Application Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSection.Security}>SecurityApplicationSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
