import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection, RcText } from "@financeone/core-ui";

import { AvailableProductTypesRequestBody } from "manifold/HttpRequestBodies";
import { AvailableProductTypesResponseBody } from "manifold/HttpResponseBodies";
import { CodeBlock } from "components/utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const PermittedBrokersProductType: React.FC = () => {
    const headerRows: ClassDataGridRow[] = [
        {
            name: "x-api-key",
            type: "String",
            required: true,
            description: "The API key provided to you by Finance One",
        },
        {
            name: "Content-Type",
            type: "application/json",
            required: true,
            description: "The accepted MIME type",
        },
    ];

    return (
        <RcPage title="Fetch Available Products for Broker">
            <RcSection>
                <RcText>
                    <p>Request a JSON object containing an array of all Finance One loan products available for submission by the broker code supplied.</p>
                    <p>
                        <code>https://sandbox.api.financeone.com.au/api/v1/products</code>
                    </p>
                </RcText>
            </RcSection>
            <RcSection title="Headers">
                <ClassDataGrid propertiesList={headerRows} />
            </RcSection>
            <RcSection title="Body">
                <CodeBlock language="json" code={AvailableProductTypesRequestBody}></CodeBlock>
            </RcSection>
            <RcSection title="Response">
                <CodeBlock language="json" code={AvailableProductTypesResponseBody}></CodeBlock>
            </RcSection>
            <RcSection title="References">
                <ul>
                    <li>
                        <Link to={RouteTable.Reference.ProductType}>ProductType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
