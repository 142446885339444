import { RcDataGrid, RcDataGridColumn, RcDataGridRow, RcText } from "@financeone/core-ui";

import React from "react";

export interface EnumDataGridRow {
    value: string;
    description: string;
}

interface Props {
    enumRows: EnumDataGridRow[];
}

const columns: RcDataGridColumn<EnumDataGridRow>[] = [
    {
        name: "Value",
        title: "Value",
        width: 50,
        render: (e) => {
            return <RcText>{e.value}</RcText>;
        },
    },
    {
        name: "Description",
        title: "Description",
        width: 50,
        render: (e) => {
            return <RcText>{e.description}</RcText>;
        },
    },
];

function parseRow(row: EnumDataGridRow): RcDataGridRow {
    return { name: row.value };
}

export const EnumDataGrid: React.FunctionComponent<Props> = ({ enumRows }) => {
    return <RcDataGrid columns={columns} rows={parseRow} data={enumRows} />;
};
