import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../../utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const ExampleHouseholdExpensesApplicationSections = [
    {
        livingExpenses: 1000,
        minorDependants: 2,
        adultDependants: 1,
        expensesIncludeDependants: true,
        sharesLivingExpenses: false,
    },
    {
        livingExpenses: 800,
        minorDependants: 2,
        adultDependants: 1,
        expensesIncludeDependants: false,
        minorExpenses: 200,
        adultExpenses: 500,
        sharesLivingExpenses: true,
        livingExpensePercentage: 50,
    },
];

export const HouseholdExpensesApplicationSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "livingExpenses",
            type: "decimal",
            required: true,
            description: "The total living expenses of all applicant/s on the application.",
            validation: "Must be between 0, and 10,000,000",
        },
        {
            name: "minorDependants",
            type: "int",
            required: true,
            description: "The total number of minor dependants the applicant/s are responsible for.",
            validation: "Must be between 0, and 100",
        },
        {
            name: "adultDependants",
            type: "int",
            required: true,
            description: "The total number of adult dependants the applicant/s are responsible for.",
            validation: "Must be between 0, and 100",
        },
        {
            name: "expensesIncludeDependants",
            type: "boolean",
            required: true,
            description: "Indicates whether the living expense value provided includes the expenses of any dependants listed above.",
        },
        {
            name: "minorExpenses",
            type: "decimal",
            required: "This is required if expensesIncludeDependants is set to false.",
            description: "The total expenses of the minor dependants the applicant/s are responsible for.",
            validation: "Must be between 0, and 10,000,000",
        },
        {
            name: "adultExpenses",
            type: "decimal",
            required: "This is required if expensesIncludeDependants is set to false.",
            description: "The total expenses of the adult dependants the applicant/s are responsible for.",
            validation: "Must be between 0, and 10,000,000",
        },
        {
            name: "sharesLivingExpenses",
            type: "boolean",
            required: true,
            description: "Indicates if the applicants share their living expenses with other individuals outside of the application.",
        },
        {
            name: "livingExpensePercentage",
            type: "decimal",
            required: "This is required if sharesLivingExpenses is set to true.",
            description: "The percentage value of their total living expenses, that the applicant/s pay.",
            validation: "Must be between 0, and 100",
        },
    ];

    return (
        <RcPage title="Household Expenses Section" description="Application Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Dependant Expenses Provided Validator" link={RouteTable.Reference.ComplexValidators.DependantExpensesProvided}>
                    Expense values for minor and/or adult dependants should be provided if the applicant indicates that their living expense value does not include these dependant expenses.
                </ValidatorReference>
                <ValidatorReference heading="Shared Living Expenses Exist Validator" link={RouteTable.Reference.ComplexValidators.SharedLivingExpensesExist}>
                    If the applicant has indicated that they share their living expenses, the percentage of their living expenses that they pay should be provided.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExampleHouseholdExpensesApplicationSections[0]} />
                <CodeBlock code={ExampleHouseholdExpensesApplicationSections[1]} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.Individual}>IndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.SoleTrader}>SoleTraderApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
