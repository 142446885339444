import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const SoleTraderApplicationType: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "uploadedDocuments",
            type: "string",
            required: "Not required for validation requests.",
            description: "The UUID received from the document upload endpoint. Contains the documents relevant to the application.",
            validation: "Must be 36 characters in length",
        },
        {
            name: "productType",
            type: "DynamicProductType",
            typeLink: RouteTable.Reference.ProductType,
            required: true,
            description: "The product ID for the application.",
        },
        {
            name: "loan",
            type: "LoanSection",
            typeLink: RouteTable.ApplicationSection.Loan,
            required: true,
            description: "The purpose of the loan and applicant payment preferences.",
        },
        {
            name: "financial",
            type: "FinancialSection",
            typeLink: RouteTable.ApplicationSection.Financial,
            required: true,
            description: "Financial breakdown of the proposed loan.",
        },
        {
            name: "security",
            type: "SecuritySection",
            typeLink: RouteTable.ApplicationSection.Security,
            required: "This is required if the product selected is a secured loan type.",
            description: "A summary of each proposed security item.",
        },
        {
            name: "householdExpenses",
            type: "HouseholdExpensesSection",
            typeLink: RouteTable.ApplicationSection.HouseholdExpenses,
            required: true,
            description: "The living expenses and dependants of the applicant/s.",
        },
        {
            name: "additionalInformation",
            type: "AdditionalInformationSection",
            typeLink: RouteTable.ApplicationSection.AdditionalInformation,
            required: true,
            description: "Any additional notes regarding the proposed loan.",
        },
        {
            name: "soleTrader",
            type: "SoleTrader",
            typeLink: RouteTable.ApplicantType.SoleTrader,
            required: true,
            description: "The sole trader applicant, applying for the loan.",
        },
    ];
    return (
        <RcPage title="Sole Trader Application" description="Application Type">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Product Type Constraints Validator" link={RouteTable.Reference.ComplexValidators.ProductTypeConstraints}>
                    Ensures that the term and lend amount fall within the constraints of the selected product. Also checks that the submitting broker has permission to submit this product type.
                </ValidatorReference>
            </RcSection>
        </RcPage>
    );
};
