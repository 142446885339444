import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const IndividualApplicantType: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "personal",
            type: "Personal",
            typeLink: RouteTable.ApplicantSection.Personal,
            required: true,
            description: "Basic details regarding the applicant themselves.",
        },
        {
            name: "licence",
            type: "Licence",
            typeLink: RouteTable.ApplicantSection.Licence,
            required: true,
            description: "Details of any vehicle or marine licences owned by the applicant.",
        },
        {
            name: "residential",
            type: "Residential",
            typeLink: RouteTable.ApplicantSection.Residential,
            required: true,
            description: "The applicant's current residential details, and past 3 years of residential history.",
        },
        {
            name: "employment",
            type: "StandardEmployment",
            typeLink: RouteTable.SharedModel.StandardEmployment,
            required: true,
            description: "The applicant's current employment details, and past 3 years of employment history.",
        },
        {
            name: "creditHistory",
            type: "CreditHistory",
            typeLink: RouteTable.ApplicantSection.CreditHistory,
            required: true,
            description: "A brief description of the applicant's credit file.",
        },
        {
            name: "incomes",
            type: "Income[]",
            typeLink: RouteTable.ApplicantSection.Income,
            required: true,
            description: "A list of the applicant's current income streams.",
        },
        {
            name: "expenses",
            type: "Expense[]",
            typeLink: RouteTable.ApplicantSection.Expenses,
            required: true,
            description: "A list of the applicant's current expenses.",
        },
        {
            name: "liabilities",
            type: "Liabilities",
            typeLink: RouteTable.ApplicantSection.Liabilities,
            required: true,
            description: "A summary of the any liabilities the applicant is responsible for.",
        },
        {
            name: "other",
            type: "Other",
            typeLink: RouteTable.ApplicantSection.Other,
            required: true,
            description: "This section covers all other miscellaneous questions regarding the applicant.",
        },
    ];
    return (
        <RcPage title="Individual Applicant" description="Applicant Type">
            <RcSection title="Remarks">
                <p>The individual applicant type is used for individual applications.</p>
            </RcSection>
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.Individual}>IndividualApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
