import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection, RcText } from "@financeone/core-ui";

import { CodeBlock } from "components/utils/CodeBlock/CodeBlock";
import { DocumentUploadResponseBody } from "manifold/HttpResponseBodies";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const DocumentUpload: React.FC = () => {
    const headerRows: ClassDataGridRow[] = [
        {
            name: "x-api-key",
            type: "String",
            required: true,
            description: "The API key provided to you by Finance One",
        },
        {
            name: "Content-Type",
            type: "multipart/form-data",
            required: true,
            description: "This endpoint is expecting files to be submitted using HTTP formdata, and as such a 'Content-Type: multipart/form-data' header is required for a valid submission.",
        },
    ];
    return (
        <RcPage title="Document Upload">
            <RcSection>
                <RcText>
                    <p>Upload application documents required for assessment to Finance One servers prior to submission. A GUID will be returned to be included in the submission request.</p>
                    <p>
                        <code>https://sandbox.api.financeone.com.au/api/v1/documents</code>
                    </p>
                </RcText>
            </RcSection>
            <RcSection title="Headers">
                <ClassDataGrid propertiesList={headerRows} />
            </RcSection>
            <RcSection title="Body">
                <RcText>
                    <p>The body consists of a multipart/form-data compatable object</p>
                    <ul>
                        <li>
                            <strong>Accepted Documents:</strong> Only certain <code>MIME</code> types are accepted by Finance One. A list of whitelisted <code>MIME</code> types can be obtained from the
                            <Link to={RouteTable.HttpRequests.WhitelistedFiles}> Whitelisted Files </Link>endpoint.
                        </li>
                        <li>
                            <strong>Maximum Payload:</strong> Please note this endpoint enforces a <code>50MB</code> request size limit.
                        </li>
                        <li>
                            <strong>Maximum Number of Files:</strong> Please note endpoint will only accept a maximum of 30 files per request.
                        </li>
                    </ul>
                </RcText>
            </RcSection>
            <RcSection title="Response">
                <CodeBlock language="json" code={DocumentUploadResponseBody}></CodeBlock>
            </RcSection>
            <RcSection title="References">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.Individual}>IndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Company}>CompanyApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.SoleTrader}>SoleTraderApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Partnership}>PartnershipApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustIndividual}>TrustIndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustCompany}>TrustCompanyApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
