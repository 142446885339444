import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const CurrentBenefitSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "duration",
            type: "int",
            validation: "Must be between 0, and 1,200",
            required: true,
            description: "The duration, in months, that the applicant has been receiving this benefit.",
        },
        {
            name: "benefitType",
            type: "DynamicBenefits",
            typeLink: RouteTable.Reference.DynamicTypes.Benefits,
            required: true,
            description: "The type of benefit the applicant is receiving.",
        },
    ];
    return (
        <RcPage title="Current Benefit" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Benefit Income Exists Validator" link={RouteTable.Reference.ComplexValidators.BenefitIncomeExists}>
                    Current Benefit employments should have a corresponding benefit income type.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentEmployment}>CurrentEmploymentSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
