import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const MotorbikeTypeEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "road_bike",
            description: "Road Bike.",
        },
        {
            value: "cruiser",
            description: "Cruiser.",
        },
        {
            value: "atv_or_quad",
            description: "Atv Or Quad.",
        },
        {
            value: "dirt_bike",
            description: "Dirt Bike.",
        },
        {
            value: "sxs_or_utv",
            description: "Sxs Or Utv.",
        },
    ];
    return (
        <RcPage title="Motorbike Type" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for motorbike security item types.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSubModels.Motorbike}>MotorbikeApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
