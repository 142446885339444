import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const MotorVehicleTypeEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "bus",
            description: "Bus.",
        },
        {
            value: "cab_chassis",
            description: "Cab Chassis.",
        },
        {
            value: "convertible",
            description: "Convertible.",
        },
        {
            value: "coupe",
            description: "Coupé.",
        },
        {
            value: "hatch",
            description: "Hatch.",
        },
        {
            value: "light_truck",
            description: "Light Truck.",
        },
        {
            value: "people_mover",
            description: "People Mover.",
        },
        {
            value: "sedan",
            description: "Sedan.",
        },
        {
            value: "suv",
            description: "SUV.",
        },
        {
            value: "ute",
            description: "Ute.",
        },
        {
            value: "van",
            description: "Van.",
        },
        {
            value: "wagon",
            description: "Wagon.",
        },
    ];
    return (
        <RcPage title="Motor Vehicle Type" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for motor vehicle security item types.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSubModels.MotorVehicle}>MotorVehicleApplicationSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
