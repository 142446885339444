import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const SecuritySectionConstraintsComplexValidator: React.FC = () => {
    return (
        <RcPage title="Security Section Constraints" description="Complex Validator">
            <RcSection title="Purpose">
                <p>
                    Ensures that the security section is filled out correctly, with at least one security being provided if the applicant is not seeking a pre-approval or applying for an unsecured product. No more than 4 security items should be included for a
                    single application.
                </p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSection.Security}>SecurityApplicationSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
