import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const OtherGoodsSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "isUnderFinance",
            type: "boolean",
            required: true,
            description: "Indicates whether the item/s being secured are currently under finance.",
        },
        {
            name: "description",
            type: "string",
            required: true,
            description: "A description of the item/s being registered as security.",
            validation: "Must be between 20, and 5,000 characters in length",
        },
    ];
    return (
        <RcPage title="Other Goods" description="Application Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSection.Security}>SecurityApplicationSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
