import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const OtherApplicantSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "circumstancesChanging",
            type: "boolean",
            required: true,
            description: "Is the applicant expecting any changes to their circumstances in the foreseeable future?",
        },
        {
            name: "changeInformation",
            type: "string",
            validation: "Must be between 10, and 15,000 characters in length",
            required: "This is required if circumstancesChanging is set to true.",
            description: "Details of the changes to their circumstances that the applicant is expecting.",
        },
    ];
    return (
        <RcPage title="Other Section" description="Applicant Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Circumstance Notes Exist Validator" link={RouteTable.Reference.ComplexValidators.CircumstanceNotesExist}>
                    If the applicant is expecting a change in circumstances, an explanation of those changes must be provided.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.Individual}>IndividualApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.Guarantor}>GuarantorApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.SoleTrader}>SoleTraderApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.TrusteeIndividual}>TrusteeIndividualApplicantType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
