import { RcDataGrid, RcDataGridColumn, RcDataGridRow, RcText } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";

const columns: RcDataGridColumn<ClassDataGridRow>[] = [
    {
        name: "Name",
        title: "Name",
        width: 15,
        render: (e) => {
            return e.name;
        },
    },
    {
        name: "Type",
        title: "Type",
        width: 15,
        render: (e) => {
            const block = <code>{e.type}</code>;

            if (e.typeLink !== undefined) {
                return <Link to={e.typeLink}>{block}</Link>;
            }

            return block;
        },
    },
    {
        name: "Required",
        title: "Required",
        width: 20,
        render: (e) => {
            return typeof e.required === "string" ? (
                <>
                    <strong>Conditionally Required</strong>
                    <br />
                    <em>{e.required}</em>
                </>
            ) : e.required ? (
                <strong>Required</strong>
            ) : (
                <strong>Not-Required</strong>
            );
        },
    },
    {
        name: "Description",
        title: "Description",
        width: 50,
        render: (e) => {
            return (
                <>
                    <RcText>{e.description}</RcText>
                    {e.validation && (
                        <RcText>
                            <i>{e.validation}</i>
                        </RcText>
                    )}
                </>
            );
        },
    },
];

function parseRow(row: ClassDataGridRow): RcDataGridRow {
    return { name: row.name };
}

export interface ClassDataGridRow {
    name: string;
    type: string;
    typeLink?: string;
    required?: boolean | string;
    description: string;
    validation?: string;
}

interface Props {
    propertiesList: ClassDataGridRow[];
}

export const ClassDataGrid: React.FunctionComponent<Props> = ({ propertiesList }) => {
    return <RcDataGrid columns={columns} rows={parseRow} data={propertiesList} />;
};
