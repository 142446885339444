import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const CreditHistoryApplicantSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "details",
            type: "string",
            validation: "Must be between 0, and 10,000 characters in length",
            required: true,
            description: "A brief description of the applicant’s credit file.",
        },
    ];

    return (
        <RcPage title="Credit History Section" description="Applicant Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.Individual}>IndividualApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.Guarantor}>GuarantorApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.SoleTrader}>SoleTraderApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.CommercialEntity}>CommercialEntityApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.TrusteeIndividual}>TrusteeIndividualApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.TrusteeCompany}>TrusteeCompanyApplicantType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
