import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const PreviousEmploymentsSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "standard",
            type: "PreviousStandardEmployment[]",
            typeLink: RouteTable.ApplicantSubModels.PreviousStandardEmployment,
            required: true,
            description: "",
        },
        {
            name: "self",
            type: "PreviousSelfEmployment[]",
            typeLink: RouteTable.ApplicantSubModels.PreviousSelfEmployment,
            required: true,
            description: "",
        },
        {
            name: "unemployed",
            type: "PreviousUnemployed[]",
            typeLink: RouteTable.ApplicantSubModels.PreviousUnemployed,
            required: true,
            description: "",
        },
        {
            name: "benefit",
            type: "PreviousBenefit[]",
            typeLink: RouteTable.ApplicantSubModels.PreviousBenefit,
            required: true,
            description: "",
        },
    ];
    return (
        <RcPage title="Previous Employments" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PreviousEmployment}>PreviousEmploymentApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
