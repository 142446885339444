import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const CreditFacilityLiabilitiesDynamicType: React.FC = () => {
    return (
        <RcPage title="Credit Facility Liability Types" description="Dynamic Type">
            <RcSection title="Remarks">
                <p>
                    Credit facility liability types are a dynamically populated enumerated type. To obtain a list of valid values, please use the Dynamic Types endpoint. All values retrieved from this endpoint should be added into the application JSON in a
                    string format.
                </p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CreditFacility}>CreditFacilityLiabilityApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
