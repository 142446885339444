import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const RecreationalAssetTypeEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "caravan",
            description: "Caravan.",
        },
        {
            value: "camper_trailer",
            description: "Camper Trailer.",
        },
        {
            value: "pop_top",
            description: "PopTop.",
        },
        {
            value: "expander",
            description: "Expander.",
        },
        {
            value: "off_road_caravan",
            description: "Off Road Caravan.",
        },
        {
            value: "off_road_camper",
            description: "Off Road Camper.",
        },
        {
            value: "trailer",
            description: "Trailer.",
        },
        {
            value: "horse_trailer",
            description: "Horse Trailer.",
        },
        {
            value: "motor_home",
            description: "Motor Home.",
        },
        {
            value: "camper_van",
            description: "Camper Van.",
        },
    ];
    return (
        <RcPage title="Recreational Asset Type" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for recreational asset security item types.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSubModels.RecreationalAsset}>RecreationalAssetApplicationSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
