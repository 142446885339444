import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const LiabilitiesApplicantSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "general",
            type: "GeneralLiability[]",
            typeLink: RouteTable.ApplicantSubModels.GeneralLiability,
            required: true,
            description: "A list of general liabilities the applicant is responsible for.",
        },
        {
            name: "creditFacilities",
            type: "CreditFacilityLiability[]",
            typeLink: RouteTable.ApplicantSubModels.CreditFacility,
            required: true,
            description: "A list of credit facility liabilities the applicant is responsible for.",
        },
    ];
    return (
        <RcPage title="Liabilities Section" description="Applicant Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantType.Individual}>IndividualApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.SoleTrader}>SoleTraderApplicantType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantType.CommercialEntity}>CommercialEntityApplicantType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
