import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const PersonalContactPhoneExistsComplexValidator: React.FC = () => {
    return (
        <RcPage title="Personal Contact Phone Exists" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that each applicant provides at least one 'home_phone' or 'mobile_phone' type contact method.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Personal}>PersonalApplicantSection</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.SharedModel.ContactMethod}>ContactMethodSharedModel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
