import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../manifold/RouteTable";

export const ProductType: React.FC = () => {
    return (
        <RcPage title="Product Types" description="References">
            <RcSection title="Remarks">
                <p>Product types are a dynamically populated enumerated type.</p>
                <p>
                    To obtain a list of valid values please use the Product Types endpoint. This will provide product information such as minimum and maximum loan term and lend amounts, as well as the product ID's which should be included in an application's
                    top level <code>productTypeId</code> field in a string format.
                </p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.Individual}>IndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.SoleTrader}>SoleTraderApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Company}>CompanyApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Partnership}>PartnershipApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustIndividual}>TrustIndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustCompany}>TrustCompanyApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
