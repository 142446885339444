import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const CurrentSelfEmploymentsSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "duration",
            type: "int",
            validation: "Must be between 0, and 1,200",
            required: true,
            description: "The duration, in months, of the self-employment.",
        },
        {
            name: "occupation",
            type: "string",
            validation: "Must be between 2, and 100 characters in length",
            required: true,
            description: "The role the applicant is employed as.",
        },
        {
            name: "entity",
            type: "Entity",
            typeLink: RouteTable.ApplicantSubModels.Entity,
            required: true,
            description: "Details of the applicant's self-employment entity.",
        },
    ];
    return (
        <RcPage title="Current Self Employment" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentEmployment}>CurrentEmploymentSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
