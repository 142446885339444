import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const CurrentEmploymentsSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "standard",
            type: "CurrentStandardEmployment[]",
            typeLink: RouteTable.ApplicantSubModels.CurrentStandardEmployment,
            required: true,
            description: "A list of standard employments the applicant is currently working.",
        },
        {
            name: "self",
            type: "CurrentSelfEmployment[]",
            typeLink: RouteTable.ApplicantSubModels.CurrentSelfEmployment,
            required: true,
            description: "A list of self employments that applicant is currently working.",
        },
        {
            name: "unemployed",
            type: "CurrentUnemployed[]",
            typeLink: RouteTable.ApplicantSubModels.CurrentUnemployed,
            required: true,
            validation: "This list should only have items if no other current employment types have been provided.",
            description: "A list of unemployment periods the applicant is currently experiencing.",
        },
        {
            name: "benefit",
            type: "CurrentBenefit[]",
            typeLink: RouteTable.ApplicantSubModels.CurrentBenefit,
            required: true,
            description: "A list of government benefits the applicant is currently receiving.",
        },
    ];
    return (
        <RcPage title="Current Employments" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Current Unemployment Check Validator" link={RouteTable.Reference.ComplexValidators.CurrentUnemploymentCheck}>
                    An applicant should not have a current unemployment if any other current employment types have been provided.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentEmployment}>CurrentEmploymentSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
