import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { CodeBlock } from "../../../utils/CodeBlock/CodeBlock";
import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const ExampleSecurityApplicationSections = [
    {
        preapproval: true,
        motorVehicles: [],
        motorbikes: [],
        recreationalAssets: [],
        allPAAP: [],
        otherGoods: [],
    },
    {
        preapproval: false,
        motorVehicles: ["..."],
        motorbikes: [],
        recreationalAssets: [],
        allPAAP: [],
        otherGoods: [],
    },
];

export const SecurityApplicationSection: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "preapproval",
            type: "boolean",
            required: true,
            description: "Indicates whether the applicant is seeking pre-approval for the proposed loan. If set to true, a security item will not be required for a valid submission.",
        },
        {
            name: "motorVehicles",
            type: "MotorVehicle[]",
            typeLink: RouteTable.ApplicationSubModels.MotorVehicle,
            required: true,
            description: "A list of motor vehicle type security items for the proposed loan.",
        },
        {
            name: "motorbikes",
            type: "Motorbike[]",
            typeLink: RouteTable.ApplicationSubModels.Motorbike,
            required: true,
            description: "A list of motorbike type security items for the proposed loan.",
        },
        {
            name: "recreationalAssets",
            type: "RecreationalAsset[]",
            typeLink: RouteTable.ApplicationSubModels.RecreationalAsset,
            required: true,
            description: "A list of recreational asset type security items for the proposed loan.",
        },
        {
            name: "watercraft",
            type: "Watercraft[]",
            typeLink: RouteTable.ApplicationSubModels.Watercraft,
            required: true,
            description: "A list of watercraft type security items for the proposed loan.",
        },
        {
            name: "allPAAP",
            type: "AllPAAP[]",
            typeLink: RouteTable.ApplicationSubModels.AllPAAP,
            required: true,
            description: "A list of All PAAP type security items for the proposed loan.",
        },
        {
            name: "otherGoods",
            type: "OtherGoods[]",
            typeLink: RouteTable.ApplicationSubModels.OtherGoods,
            required: true,
            description: "A list of other goods type security items for the proposed loan.",
        },
    ];
    return (
        <RcPage title="Security Section" description="Application Section">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Security Section Constraints Validator" link={RouteTable.Reference.ComplexValidators.SecuritySectionConstraints}>
                    At least one security should be provided if the applicant is not seeking a pre-approval or unsecured product. No more than 4 security items should be provided for a single application.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Examples">
                <CodeBlock code={ExampleSecurityApplicationSections[0]} />
                <CodeBlock code={ExampleSecurityApplicationSections[1]} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.Individual}>IndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.SoleTrader}>SoleTraderApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Company}>CompanyApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Partnership}>PartnershipApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustIndividual}>TrustIndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustCompany}>TrustCompanyApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
