import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const CommercialEntityApplicantType: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "creditHistory",
            type: "CreditHistory",
            typeLink: RouteTable.ApplicantSection.CreditHistory,
            required: true,
            description: "A brief description of the applicant's credit file.",
        },
        {
            name: "incomes",
            type: "Income[]",
            typeLink: RouteTable.ApplicantSection.Income,
            required: true,
            description: "A list of the applicant's current income streams.",
        },
        {
            name: "expenses",
            type: "Expense[]",
            typeLink: RouteTable.ApplicantSection.Expenses,
            required: true,
            description: "A list of the applicant's current expenses.",
        },
        {
            name: "liabilities",
            type: "Liabilities",
            typeLink: RouteTable.ApplicantSection.Liabilities,
            required: true,
            description: "A summary of the any liabilities the applicant is responsible for.",
        },
        {
            name: "entity",
            type: "Entity",
            typeLink: RouteTable.ApplicantSection.Entity,
            required: true,
            description: "Details of the entity applying for the proposed loan.",
        },
    ];
    return (
        <RcPage title="Commercial Entity Applicant" description="Applicant Type">
            <RcSection title="Remarks">
                <p>The commercial entity applicant type is used for all applications where a commercial entity is applying as the primary borrower of a loan.</p>
            </RcSection>
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationType.Company}>CompanyApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.Partnership}>PartnershipApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustIndividual}>TrustIndividualApplicationType</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicationType.TrustCompany}>TrustCompanyApplicationType</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
