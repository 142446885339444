import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";
import { ValidatorReference } from "../../../utils/ValidatorReference/ValidatorReference";

export const InsuranceSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "type",
            type: "DynamicInsuranceType",
            typeLink: RouteTable.Reference.DynamicTypes.Insurances,
            required: true,
            description: "The type of insurance policy being purchased. A list of valid values for this field can be retrieved from the Dynamic Types API endpoint.",
        },
        {
            name: "name",
            type: "string",
            required: true,
            description: "The name of the company providing the insurance policy.",
            validation: "Must be between 2, and 50 characters in length",
        },
        {
            name: "value",
            type: "decimal",
            required: true,
            description: "The value of the insurance policy being purchased.",
            validation: "Must be between 0, and 10,000,000",
        },
    ];

    return (
        <RcPage title="Insurance" description="Application Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Validation">
                <ValidatorReference heading="Single Insurance Type Check Validator" link={RouteTable.Reference.ComplexValidators.SingleInsuranceTypeCheck}>
                    Only one insurance policy of each type should be provided.
                </ValidatorReference>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSection.Loan}>LoanApplicationSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
