import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const PointOfContactProvidedComplexValidator: React.FC = () => {
    return (
        <RcPage title="Point of Contact Provided" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that each point of contact listed in the application has at least one phone or email contact field filled out.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.SharedModel.PointOfContact}>PointOfContactSharedModel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
