import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const BasisEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "full_time",
            description: "Full Time.",
        },
        {
            value: "part_time",
            description: "Part Time.",
        },
        {
            value: "casual",
            description: "Casual.",
        },
        {
            value: "other",
            description: "Other.",
        },
    ];
    return (
        <RcPage title="Basis" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for employment basis descriptions.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentStandardEmployment}>CurrentStandardEmploymentApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PreviousStandardEmployment}>PreviousStandardEmploymentApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
