import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const PreviousUnemployedSharedModel: React.FC = () => {
    const rows: ClassDataGridRow[] = [
        {
            name: "duration",
            type: "int",
            validation: "Must be between 0, and 1,200",
            required: true,
            description: "The duration, in months, of the period of unemployment.",
        },
        {
            name: "endDate",
            type: "string",
            required: true,
            validation: "Date should be submitted in 'yyyy-MM-dd' format. Must be a date between 1 day, and 100 years old at the time of submission",
            description: "The date this unemployment period ended.",
        },
        {
            name: "notes",
            type: "string",
            validation: "Must be between 0, and 1,000 characters in length",
            required: true,
            description: "A brief description explaining the reason/s for this period of unemployment.",
        },
    ];
    return (
        <RcPage title="Previous Unemployment" description="Applicant Submodel">
            <RcSection title="Properties">
                <ClassDataGrid propertiesList={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.PreviousEmployment}>PreviousEmploymentApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
