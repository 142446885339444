import { EnumDataGrid, EnumDataGridRow } from "components/utils/EnumDataGrid/EnumDataGrid";
import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const WatercraftTypeEnum: React.FC = () => {
    const rows: EnumDataGridRow[] = [
        {
            value: "personal_watercraft",
            description: "Personal Watercraft.",
        },
        {
            value: "cruiser",
            description: "Cruiser.",
        },
        {
            value: "leisure",
            description: "Leisure.",
        },
        {
            value: "ski_boat",
            description: "Ski Boat.",
        },
        {
            value: "fishing",
            description: "Fishing.",
        },
        {
            value: "sail",
            description: "Sail.",
        },
    ];
    return (
        <RcPage title="Watercraft Type" description="Enumerated Type">
            <RcSection title="Remarks">
                <p>A list of accepted values for watercraft security item types.</p>
            </RcSection>
            <RcSection title="Values">
                <EnumDataGrid enumRows={rows} />
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicationSubModels.Watercraft}>WatercraftApplicationSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
