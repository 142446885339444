import { Link } from "react-router-dom";
import { RcIcon } from "@financeone/core-ui";
import React from "react";

interface Props {
    heading: string;
    link: string;
    children?: React.ReactNode;
}

export class ValidatorReference extends React.Component<Props> {
    render() {
        return (
            <div className="validator-reference">
                <h1 className="rc-h4">
                    {this.props.heading}
                    <Link to={this.props.link} className="rc-ml-1">
                        <RcIcon icon="Link" />
                    </Link>
                </h1>
                <p>{this.props.children}</p>
            </div>
        );
    }
}
