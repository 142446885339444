import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const BenefitIncomeExistsComplexValidator: React.FC = () => {
    return (
        <RcPage title="Benefit Income Exists" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures each current benefit type employment has a corresponding income of the same type to match it.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentBenefit}>CurrentBenefitApplicantSubmodel</Link>
                    </li>
                    <li>
                        <Link to={RouteTable.ApplicantSection.Income}>IncomeApplicantSection</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
