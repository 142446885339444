import { BASE_THEMES, RcButton, RcPanel, RcSpacer, RcStack, RcTitle, THEME_DEFAULT, ThemeLoad } from "@financeone/core-ui";
import React, { useEffect, useState } from "react";

type ThemeType = "dark" | "default";

export const ApplicationCommandBar: React.FunctionComponent = () => {
    const [showSupportPanel, setShowSupportPanel] = useState(false);
    const [currentTheme, setCurrentTheme] = useState<ThemeType>((localStorage.getItem("app_theme") as ThemeType) || "default");

    useEffect(() => {
        switch (currentTheme) {
            case "default":
                ThemeLoad(THEME_DEFAULT);
                localStorage.removeItem("app_theme");
                break;
            case "dark":
                ThemeLoad(BASE_THEMES.find((s) => s.name === "dark")!);
                localStorage.setItem("app_theme", "dark");
        }
    }, [currentTheme]);

    function toggleTheme() {
        switch (currentTheme) {
            case "default":
                setCurrentTheme("dark");
                break;
            case "dark":
                setCurrentTheme("default");
        }
    }

    return (
        <>
            <RcStack orientation="vertical" grow horizontalAlign="stretch">
                <RcStack orientation="horizontal" grow>
                    <img src={currentTheme === "default" ? "/logo-coloured.svg" : "/logo.svg"} height="40" alt="Finance one" />
                    <RcSpacer orientation="horizontal" size="large" />
                    <RcStack orientation="vertical" grow horizontalAlign="stretch">
                        <RcTitle
                            title="API Documentation"
                            nav={
                                <>
                                    <RcButton fill="empty" icon={currentTheme === "default" ? "Moon" : "Sun"} onClick={() => toggleTheme()} />
                                    <RcButton variant="primary" onClick={() => setShowSupportPanel(true)} icon="Phone">
                                        API Support
                                    </RcButton>
                                </>
                            }
                        />
                    </RcStack>
                </RcStack>
            </RcStack>
            {showSupportPanel && (
                <RcPanel onClose={() => setShowSupportPanel(false)} icon="Phone" title="API Support">
                    <h3 className="rc-h3">Finance One Support</h3>
                    <p>If you require assistance, please use the following contact methods:</p>
                    <div className="rc-mt-2 rc-mb-2">
                        <RcTitle size="s" title="Finance One" nav={<a href="tel://1800346663">1800 346 663</a>} />
                        <RcTitle size="s" title="Broker Support" nav={<a href="tel://0747668725">(07) 4766 8725</a>} />
                        <RcTitle size="s" title="API Support Email" nav={<a href="mailto:api@financeone.com.au">api@financeone.com.au</a>} />
                    </div>
                </RcPanel>
            )}
        </>
    );
};
