import { RcPage, RcSection } from "@financeone/core-ui";

import { Link } from "react-router-dom";
import React from "react";
import { RouteTable } from "../../../../manifold/RouteTable";

export const RentingLeaseIndividualsProvidedComplexValidator: React.FC = () => {
    return (
        <RcPage title="Renting Lease Individuals Provided" description="Complex Validator">
            <RcSection title="Purpose">
                <p>Ensures that the applicant includes the number of individuals on their lease, if the current residential status is set to 'renting'.</p>
            </RcSection>
            <RcSection title="Used By">
                <ul>
                    <li>
                        <Link to={RouteTable.ApplicantSubModels.CurrentResidential}>CurrentResidentialApplicantSubmodel</Link>
                    </li>
                </ul>
            </RcSection>
        </RcPage>
    );
};
